/* Controller for users listing  */
/* Initiated by diyu (23/01/2021) */
/* Modified by diyu (28/01/2021) */

import React, { Component } from "react";
import {Row, Col, Button } from "reactstrap";
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import Widget from "../../component/Admin/Widget";
import s from "./Tables.modules.scss";
import axios from 'axios';
import swal from 'sweetalert';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import db from '../../config/firebase';
import * as NODES from '../../constants/nodes';
import moment from 'moment';
import {Helmet} from "react-helmet";
import { differenceInYears } from "date-fns";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactExport from "react-data-export";
import * as stringSlice from 'string-slice';
import * as JsSearch from 'js-search';
const { SearchBar, ClearSearchButton } = Search;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const AgeList=['All Age',16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120]
class Tables extends Component {
	constructor(props) {
		super(props);
		this.state = { 
            errors: false,
            response: false,
            indication: "No record found!",
            users: [],
            page: 1, 
            sizePerPage: parseInt(process.env.REACT_APP_PER_PAGE) || 10,
            searchUser: '', 
            sortField: 'userId',
            sortOrder: 'asc',
			loading: false,
            filteredUser: [],
            selectedAge:'All Age',
            selectedAgetwo:'All Age',
            selectedGender:'all',
            searchphone:''
        };
	}
   
    
    onChangeSearchUser = e => {
        this.setState({
            searchUser: e.target.value
        });
       // this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedGender);
        var searchUser = e.target.value;
        //Search users in list
        if(searchUser!=""){
        const usersList = this.state.filteredUser.filter(user => {
            if(user.name !== undefined) {
                return user.name.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1;
            }
        });
        this.setState({
            ...this.state,
            searchUser: e.target.value,
            filteredUser: usersList,
        });}
        else{
            this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedAgetwo,this.state.selectedGender,this.state.searchphone)
        }
    };

    
    onChangeSearchPhone = e => {
 
        this.setState({
            searchphone: e.target.value
        });
        var searchUser = e.target.value;
        //alert(searchUser)
        if(searchUser!=""){
           // alert(searchUser.length)
            if(searchUser.length>3){
               // alert("Inner")
                const usersList = this.state.filteredUser.filter(user => {
                    if(user.phone !== undefined) {
                        return user.phone.indexOf(e.target.value) !== -1;
                    }
                });
                this.setState({
                    ...this.state,
                    searchphone: e.target.value,
                    filteredUser: usersList,
                }); 
            }else if(searchUser.length<=3){
                const usersList = this.state.filteredUser.filter(user => {
                    if(user.phonecode !== undefined) {
                        return user.phonecode.indexOf(searchUser) !== -1;
                    }
                });
                this.setState({
                    ...this.state,
                    searchphone: e.target.value,
                    filteredUser: usersList,
                }); 
            }
           
        }else{
            //alert()
            this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedAgetwo,this.state.selectedGender,e.target.value);
       
        }
       
    };

    handleChangeAge=(e)=>{ 
         this.setState({
            selectedAge:e.target.value, 
        });
       // alert(this.state.searchphone)
        this.loadUsersFromServer(this.state.page,e.target.value,this.state.selectedAgetwo,this.state.selectedGender,this.state.searchphone);
    }
    handleChangeAgetwo=(e)=>{ 
        this.setState({
           selectedAgetwo:e.target.value, 
       });
       //alert(this.state.searchphone)
       this.loadUsersFromServer(this.state.page,this.state.selectedAge,e.target.value,this.state.selectedGender,this.state.searchphone);
     }
      
    handleChangeGender=(e)=>{ 
        this.setState({
            selectedGender:e.target.value, 
       });

       this.loadUsersFromServer(this.state.page,this.state.selectedAge, this.state.selectedAgetwo,e.target.value,this.state.searchphone);
  
     }
    
    limit =(string = '', limit = 0)=> {  
        return string.substring(0, limit)
      }

    /*Funtion to load users from server */
	loadUsersFromServer = (page, ageselected, selectedAgetwo, gender, searchPhonecode) => {
       console.log(searchPhonecode)
		this.setState({loading: true})
        let users = [], postCount = 0;
		NODES.getUsers().on('child_added', snapshot => {
			const usersDetail = snapshot.val();
            console.log(usersDetail.status, "fgfdhg")
            if(usersDetail.status === undefined) {
                console.log(usersDetail.status, "if")
                const blockUsersref = NODES.getUserReference(snapshot.key);
                var updateData = {
                    status: "0"
                }
                blockUsersref.update(updateData)
            }
			var user = {
				userId: snapshot.key,
				name: usersDetail.fullName,
				email: usersDetail.email ? usersDetail.email : "-",
				userName: usersDetail.userName ? usersDetail.userName : "-",
				phone: usersDetail.phoneNumber,
                phonecode:this.limit(usersDetail.phoneNumber,3),
				age: usersDetail.age !== "" && usersDetail.age !== undefined && moment(usersDetail.age).isValid() ? differenceInYears(new Date(), new Date(usersDetail.age)) : '--',
				gender: usersDetail.gender ? usersDetail.gender : "-",
				lastLogin: usersDetail.userState ? moment(usersDetail.userState.dateTime).format("DD/MM/YYYY HH:mm:ss") : "-",
				state: usersDetail.userState ? usersDetail.userState.state : "-",
				status: usersDetail.status ? usersDetail.status : "-",
			}
			users.push(user);
          //  alert(searchPhonecode.length)
         if(searchPhonecode && searchPhonecode.length>3){
            // alert(1)

            if(ageselected=='All Age' && selectedAgetwo=='All Age' &&  gender=='all' && searchPhonecode==""){
                //console.log("Full")
                 this.setState({
                     filteredUser: users,
                     loading: false,
                 });
             }
            else if(ageselected=='All Age' && selectedAgetwo=='All Age' && gender!='all' && searchPhonecode==""){
               // console.log("Only Gender")
                 this.setState({
                     filteredUser: users.filter(d => d.gender == gender),
                     loading: false,
                 });
             }
             else if(ageselected=='All Age' && selectedAgetwo=='All Age' && gender=='all' && searchPhonecode!=""){
                // console.log("Only Gender 22")
                  this.setState({
                      filteredUser: users.filter(d => d.phone==searchPhonecode),
                      loading: false,
                  });
              }
              else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender!='all' && searchPhonecode!=""){
                 //console.log("Only Gender & Search wiht age first")
                 this.setState({
                     filteredUser: users.filter(d => d.gender == gender &&  d.phone==searchPhonecode && d.age >= parseInt(ageselected)),
                     loading: false,
                 });
             }
             else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender!='all' && searchPhonecode!=""){
                 //console.log("Only Gender & Search wiht age two")
                 if(ageselected>selectedAgetwo){
                     this.setState({
                         errors: "Please select to age greater then from age"
                     });
                     // alert("Please select to age greater then from age");
                     // return false;
                 }
                 else if(ageselected!="All Age"){
                     this.setState({
                         filteredUser: users.filter(d => d.gender == gender &&  d.phone==searchPhonecode && d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                         loading: false,
                     });
                 }else{
                     this.setState({
                         errors: "Please select from age then to age"
                     });
                     // alert("Please select from age then to age");
                     // return false;
                 } 
             }
             else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender!=='all' && searchPhonecode==""){
                // console.log("Only Gender & Search wiht age two serch blank")
                 if(ageselected>selectedAgetwo){
                     this.setState({
                         errors: "Please select to age greater then from age"
                     });
                     // alert("Please select to age greater then from age");
                     // return false;
                 }
                 else if(ageselected!="All Age"){
                     this.setState({
                         filteredUser: users.filter(d => d.gender == gender  && d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                         loading: false,
                     });
                 } else {
                     this.setState({
                         errors: "Please select from age then to age"
                     });
                     // alert("Please select from age then to age");
                     // return false;
                 }
             }
             else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender=='all' && searchPhonecode==""){
                // console.log("Only Gender & Search wiht age two serch blank all gender")
                 //alert(searchPhonecode)
                 if(ageselected>selectedAgetwo){
                     this.setState({
                         errors: "Please select to age greater then from age"
                     });
                     // alert("Please select to age greater then from age");
                     // return false;
                 }
                 else if(ageselected!="All Age"){
                     this.setState({
                         filteredUser: users.filter(d => d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                         loading: false,
                     });
                 }else{
                     this.setState({
                         errors: "Please select from age then to age"
                     });
                     // alert("Please select from age then to age");
                     // return false;
                 } 
             }
             else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender=='all' && searchPhonecode!=""){
                // console.log("Only Gender & Search wiht age two serch blank all gender new")
                 if(ageselected>selectedAgetwo) {
                     this.setState({
                         errors: "Please select to age greater then from age"
                     });
                     // alert("Please select to age greater then from age");
                     // return false;
                 }
                 else if(ageselected!="All Age"){
                     this.setState({
                         filteredUser: users.filter(d =>d.phone==searchPhonecode && d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                         loading: false,
                     });
                 }else {
                     this.setState({
                         errors: "Please select from age then to age"
                     });
                     // alert("Please select from age then to age");
                     // return false;
                 }  
             }
             else if(ageselected=='All Age' && selectedAgetwo=='All Age' && gender!=='all' && searchPhonecode!=""){
                 //console.log("Only Gender & Search wiht age two serch blank all gender new hagaa")
                 this.setState({
                     filteredUser: users.filter(d =>d.phone==searchPhonecode && d.gender ==gender),
                     loading: false,
                 });
             }
             else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender=='all' && searchPhonecode!=""){
                // console.log("Only Gender & Search wiht age two serch blank all gender new hahaha11")
                 this.setState({
                     filteredUser: users.filter(d => d.phone==searchPhonecode &&  d.age >= parseInt(ageselected)),
                     loading: false,
                 });
             }
             else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender=='all' && searchPhonecode==""){
                 // console.log("Only Gender & Search wiht age two serch blank all gender new hahaha11")
                 this.setState({
                     filteredUser: users.filter(d =>  d.age >= parseInt(ageselected)),
                     loading: false,
                 });
              }
              else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender!=='all' && searchPhonecode==""){
                 // console.log("Only Gender & Search wiht age two serch blank all gender new hahaha11")
                 this.setState({
                     filteredUser: users.filter(d => d.gender=gender && d.age >= parseInt(ageselected)),
                     loading: false,
                 });
             }





         }
         else{

            if(ageselected=='All Age' && selectedAgetwo=='All Age' &&  gender=='all' && searchPhonecode==""){
               //console.log("Full")
                this.setState({
                    filteredUser: users,
                    loading: false,
                });
            }
           else if(ageselected=='All Age' && selectedAgetwo=='All Age' && gender!='all' && searchPhonecode==""){
              // console.log("Only Gender")
                this.setState({
                    filteredUser: users.filter(d => d.gender == gender),
                    loading: false,
                });
            }
            else if(ageselected=='All Age' && selectedAgetwo=='All Age' && gender=='all' && searchPhonecode!=""){
               // console.log("Only Gender 22")
                 this.setState({
                     filteredUser: users.filter(d => d.phonecode==searchPhonecode),
                     loading: false,
                 });
             }
             else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender!='all' && searchPhonecode!=""){
                //console.log("Only Gender & Search wiht age first")
                this.setState({
                    filteredUser: users.filter(d => d.gender == gender &&  d.phonecode==searchPhonecode && d.age >= parseInt(ageselected)),
                    loading: false,
                });
            }
            else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender!='all' && searchPhonecode!=""){
                //console.log("Only Gender & Search wiht age two")
                if(ageselected>selectedAgetwo){
                    this.setState({
                        errors: "Please select to age greater then from age"
                    });
                    // alert("Please select to age greater then from age");
                    // return false;
                }
                else if(ageselected!="All Age"){
                    this.setState({
                        filteredUser: users.filter(d => d.gender == gender &&  d.phonecode==searchPhonecode && d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                        loading: false,
                    });
                }else{
                    this.setState({
                        errors: "Please select from age then to age"
                    });
                    // alert("Please select from age then to age");
                    // return false;
                } 
            }
            else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender!=='all' && searchPhonecode==""){
               // console.log("Only Gender & Search wiht age two serch blank")
                if(ageselected>selectedAgetwo){
                    this.setState({
                        errors: "Please select to age greater then from age"
                    });
                    // alert("Please select to age greater then from age");
                    // return false;
                }
                else if(ageselected!="All Age"){
                    this.setState({
                        filteredUser: users.filter(d => d.gender == gender  && d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                        loading: false,
                    });
                } else {
                    this.setState({
                        errors: "Please select from age then to age"
                    });
                    // alert("Please select from age then to age");
                    // return false;
                }
            }
            else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender=='all' && searchPhonecode==""){
               // console.log("Only Gender & Search wiht age two serch blank all gender")
                //alert(searchPhonecode)
                if(ageselected>selectedAgetwo){
                    this.setState({
                        errors: "Please select to age greater then from age"
                    });
                    // alert("Please select to age greater then from age");
                    // return false;
                }
                else if(ageselected!="All Age"){
                    this.setState({
                        filteredUser: users.filter(d => d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                        loading: false,
                    });
                }else{
                    this.setState({
                        errors: "Please select from age then to age"
                    });
                    // alert("Please select from age then to age");
                    // return false;
                } 
            }
            else if(ageselected!=='All Age' && selectedAgetwo!=='All Age' && gender=='all' && searchPhonecode!=""){
               // console.log("Only Gender & Search wiht age two serch blank all gender new")
                if(ageselected>selectedAgetwo) {
                    this.setState({
                        errors: "Please select to age greater then from age"
                    });
                    // alert("Please select to age greater then from age");
                    // return false;
                }
                else if(ageselected!="All Age"){
                    this.setState({
                        filteredUser: users.filter(d =>d.phonecode==searchPhonecode && d.age >= parseInt(ageselected) && d.age <= parseInt(selectedAgetwo)),
                        loading: false,
                    });
                }else {
                    this.setState({
                        errors: "Please select from age then to age"
                    });
                    // alert("Please select from age then to age");
                    // return false;
                }  
            }
            else if(ageselected=='All Age' && selectedAgetwo=='All Age' && gender!=='all' && searchPhonecode!=""){
                //console.log("Only Gender & Search wiht age two serch blank all gender new hagaa")
                this.setState({
                    filteredUser: users.filter(d =>d.phonecode==searchPhonecode && d.gender ==gender),
                    loading: false,
                });
            }
            else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender=='all' && searchPhonecode!=""){
               // console.log("Only Gender & Search wiht age two serch blank all gender new hahaha11")
                this.setState({
                    filteredUser: users.filter(d => d.phonecode==searchPhonecode &&  d.age >= parseInt(ageselected)),
                    loading: false,
                });
            }
            else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender=='all' && searchPhonecode==""){
                // console.log("Only Gender & Search wiht age two serch blank all gender new hahaha11")
                this.setState({
                    filteredUser: users.filter(d =>  d.age >= parseInt(ageselected)),
                    loading: false,
                });
             }
             else if(ageselected!=='All Age' && selectedAgetwo=='All Age' && gender!=='all' && searchPhonecode==""){
                // console.log("Only Gender & Search wiht age two serch blank all gender new hahaha11")
                this.setState({
                    filteredUser: users.filter(d => d.gender=gender && d.age >= parseInt(ageselected)),
                    loading: false,
                });
            }
        }
		
    });
        
    }

    componentDidMount() {
     
        this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedAgetwo,this.state.selectedGender,this.state.searchphone);
       
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder,selectedAge,selectedAgetwo,selectedGender }) => {
        this.setState({ page: page, sortField: sortField, sortOrder: sortOrder,selectedAge:selectedAge,selectedAgetwo:selectedAgetwo,selectedGender:selectedGender }, () => {
            this.loadUsersFromServer(page,selectedAge,selectedAgetwo,selectedGender);
        });
    }

    //Search Process
    onChangeSearch = e => {
        console.log(e.target.value)
        this.setState({ search: e.target.value }, () => {
            this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedAgetwo,this.state.selectedGender,this.state.searchphone);
        });
    };

    /*Function to block user */
    blockUser = (status, userId, userName) => {
        console.log(status, userId, userName)
        swal({
            title: "",
            text: `Block ${userName}.`,
            icon: "warning",
            dangerMode: true,
            buttons: ["CANCEL", 'BLOCK']
        })
        .then(willBlock => {
            if (willBlock) {
                const blockUsersref = NODES.getUserReference(userId);
                var updateData = {
                    status: "1"
                }
                blockUsersref.update(updateData)
                this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedAgetwo,this.state.selectedGender,this.state.searchphone);
                swal("", `${userName} Blocked!`, "success");
            }
        })
    }

    /*Function to unblock user */
    unBlockUser = (status, userId, userName) => {
        console.log(status, userId, userName)
        swal({
            title: "",
            text: `Unblock ${userName}.`,
            icon: "success",
            buttons: ["CANCEL", 'UNBLOCK']
        })
        .then(willUnBlock => {
            if (willUnBlock) {
                const blockUsersref = NODES.getUserReference(userId);
                var updateData = {
                    status: "0"
                }
                blockUsersref.update(updateData)
                this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedAgetwo,this.state.selectedGender,this.state.searchphone);
                swal("", `${userName} Unblocked!`, "success");
            }
        })
    }

    ageFilter=()=>{
        this.loadUsersFromServer(this.state.page,this.state.selectedAge,this.state.selectedGender);
        
        if(this.state.selectedAge!='All Age' && this.state.selectedAgetwo!='All Age'){
            this.setState({
                filteredUser: this.state.filteredUser.filter(d =>  d.age >= parseInt(this.state.selectedAge) && d.age <= parseInt(this.state.selectedAgetwo)),
                loading: false,
            });
            console.log(this.state.filteredUser)  
        } else {
            // alert("please select First age")
            this.setState({
                errors: "Please select from age then to age"
            });
        }
    }


	render() {
		const { page, sizePerPage, totalSize, users, searchUser,searchphone, sortField, sortOrder, loading, response, errors, filteredUser } = this.state;

        let usersListing = filteredUser ;

        const handleDismiss = () => this.setState({ errors: '' });
      
		const columns = [
            {
                dataField: 'userId',
                text: "User Id",
                sort: true,
                hidden: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '150px' };
                }
            },
            {
                dataField: 'name',
                text: "Full Name",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '200px' };
                }
            },
            {
                dataField: 'userName',
                text: "User Name",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '200px' };
                }
            },
            {
                dataField: 'email',
                text: "Email",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '300px' };
                }
            },
            {
                dataField: 'phone',
                text: "Phone Number",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '160px' };
                }
            },
            {
                dataField: 'age',
                text: "Age",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '50px' };
                }
            },
            {
                dataField: 'gender',
                text: "Gender",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '90px' };
                }
            },
            {
                dataField: 'lastLogin',
                text: "Last Login",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '190px' };
                }
            },
            {
                dataField: 'state',
                text: "Status",
                sort: true,
                headerClasses: 'panel_bg',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px' };
                },
                formatter: (cell, row) => {
                    return (row.state === "Online") ? <span className="text-success">Online</span> : <span className="text-danger">Offline</span>
                }
            },
			{
				dataField: 'block',
				text: 'Action',
				classes: "text-right",
                headerClasses: 'panel_bg white_txt text-right cursor_pointer',
                headerStyle: (colum, colIndex) => {
                    return { width: '100px' };
                },
                formatter: (cell, row) => {
                    return (row.status === "0") ? <Button className="btn btn-danger" onClick={()=> this.blockUser(row.status, row.userId, row.userName)}>Block</Button> : <Button className="btn btn-info" onClick={()=> this.unBlockUser(row.status, row.userId, row.userName)}>Unblock</Button>
                }
			}       
        ];

        const defaultSorted = [{
            dataField: sortField,
            order: sortOrder
        }]; 
        //Add pagination options
        const options = {
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            // paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '25', value: 25
                }, {
                text: '50', value: 50
                }, {
                    text: '100', value: 100
                }, {
                    text: '250', value: 250
                }, {
                    text: '500', value: 500
                }, {
                    text: '1000', value: 1000
                }
            ] // A numeric array is also available. the purpose of above example is custom the text
        }; 
		return (
			<div className={s.root}>
                <Helmet>
                    <title>Kiveez - Admin Users List</title>
                </Helmet>
                {response && <Alert variant="success" onClose={handleDismiss} dismissible>
                    {response}
                </Alert>}

                {errors && <Alert variant="danger" onClose={handleDismiss} dismissible>
                    {errors}
                </Alert>}
                {/* <Row><Col md={12}></Col></Row> */}

                <div className="w-100 d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <p style={{ fontWeight: 700 }}>Users List</p>
                    </div>
                    <div>
                        <ExcelFile element={<Button className="btn btn-sm btn-primary ml-4 mt-1 btnexport">Export</Button>}>
                            <ExcelSheet data={usersListing} name="Project Members">
                                <ExcelColumn label="User Name" value="userName"/>
                                <ExcelColumn label="Email" value="email"/>
                                <ExcelColumn label="Phone" value="phone"/>
                                <ExcelColumn label="Age" value="age"/>
                                <ExcelColumn label="Gender" value="gender"/>
                                <ExcelColumn label="Last Login" value="lastLogin"/>
                                <ExcelColumn label="Status" value="state"/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>
                <Row className="d-flex align-items-end search_head" style={{marginBottom:'5%'}}>
                    <Col md={2} lg={2} xl={2}>
                        {/* <span>Gender</span> */}
                        <select style={{width: '100%'}} value={this.state.selectedGender} onChange={this.handleChangeGender}>       
                            <option  value="all">All</option>
                            <option  value="Male">Male</option>
                            <option  value="Female">Female</option>
                        </select>
                    </Col>
                    <Col md={4} lg={4} xl={4} className="pl-md-0">
                        <span><input type="search" className="form-control" placeholder="Search by phone number" value={searchphone} onChange={this.onChangeSearchPhone} /></span>        
                    </Col>
                    <Col md={6} lg={6} xl={6}>
                        <Row className="d-flex align-items-end">
                        <Col md={6} className="pl-md-0">
                            <span className="mb-2"><input type="search" className="form-control" placeholder="Search by full name" value={searchUser} onChange={this.onChangeSearchUser} /></span>     
                        </Col>
                            <Col md={3} className="pl-md-0">
                                <span>From-</span>
                                <select style={{width: '100%'}} value={this.state.selectedAge} onChange={this.handleChangeAge}>
                                    {
                                        AgeList.map((item,index)=>{
                                            return( <option key={index} value={item}>{item} </option>)
                                        })
                                    }
                                </select>
                            </Col>
                            <Col  md={3} className="pl-md-0">
                                <span>To-</span>
                                    <select style={{width: '100%'}} value={this.state.selectedAgetwo} onChange={this.handleChangeAgetwo}>
                                    {
                                    AgeList.map((item,index)=>{
                                            return( <option key={index} value={item}>{item} </option>)
                                        })
                                    }
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
				<Row>
					<Col>
                        {/* data.list.filter( record => record.name === "my Name") */}
                        { loading && <div>Loading ...</div>} 
						<div>
							<div className="table-responsive react-bootstrap-table table-bordered table-lg mt-lg mb-0 table">
								<BootstrapTable
								classes="border_1 user_table"
								keyField='userId'
								data={usersListing}
								columns={columns}
								defaultSorted={defaultSorted}
								// pagination={paginationFactory({ page, sizePerPage, totalSize })}
								pagination={paginationFactory(options)}
								bordered={false}
                                onTableChange={this.handleTableChange}
								noDataIndication={this.state.indication}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

Tables.propTypes = {
    adminAuth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    adminAuth: state.adminAuth
});

export default (connect(
    mapStateToProps
)(Tables));

// export default Tables;