import React from "react";

import s from "./Dashboard.module.scss";
import {Helmet} from "react-helmet";

class Dashboard extends React.Component {
	// constructor() {
	// 	super();
	// }

	// componentDidMount() {
	// }

	render() {
		return (
			<div className={s.root}>
				<Helmet>
                    <title>Kiveez - Admin Dashboard</title>
                </Helmet>
				Welcome to Admin Dashboard
			</div>
		);
	}
}

export default Dashboard;