
import React, { Component, Fragment }  from "react";
import {
    Link
    // ,NavLink
    } from "react-router-dom";
import logo from '../assets/img/logo-icon.png';

class Header extends Component{
    constructor(){
        super();
        this.state={

        }
    }
    componentDidMount(){
        //initialize code
    }
    render(){
        return (
            <Fragment>
                <header className="main-header">
                   <div className="header__inner">
                        <Link to="/" className="logo-top">
                            <span className="logo-icon"><img onDragStart={(e) => { e.preventDefault() }} src={logo} alt="logo"/></span>
                            <span className="logo-text">Kiveez Web</span>
                        </Link>
                   </div>
                </header>
            </Fragment>
        )
    }
}
export default Header;