/* Controller for ForwardMessageToContact*/
/* Initiated by diyu (28/01/2021) */

import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import CsLoader from "./../loader";
import swal from 'sweetalert';
import { encrypt } from '../../utils/encryption';
import user__xs from '../../assets/img/user__xs.jpg';
import * as NODES from '../../constants/nodes';

class ForwardMessageToContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactModal: false,
            search: "",
            registeredUsers: [],
            selectedContacts: [],
            sendContactChk: false,
            closeSearch: false,
            matched: false,
            selecetdCheckboxes: [],
            checked: {}
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ forwardMessageToContacts: false, selectedContacts: [], sendContactChk: false, checked: {} });
    }

    /* Function to show contact modal */
    handleShow = (forwardContacts, message, type, fileName, fileSize, fileType) => {
        var registeredUsers = forwardContacts;
        this.setState({ forwardMessageToContacts: true, registeredUsers: registeredUsers, message: message, type: type, fileName: fileName, fileSize: fileSize, fileType: fileType, closeSearch: false, search: "", selectedContacts: [], sendContactChk: false, checked: {} });
    }
    
    /* Function to search contacts */
    onChangeSearch = (e) => {
        this.setState({ 
            search: e.target.value
        });
    };
  
    /*Function to search and clear if close is clicking on search */
    onKeyDown = (e) => {
        let val = e.target.value;
        this.setState({
            closeSearch : val !== '' ? true : false
        })
         
    }

    addDefaultUserImg = (ev) => {
        ev.target.src = user__xs;
    }

    /*Function to select contact to send */
    selectContacts = (e, index, id) => {
        // const {selecetdCheckboxes}=this.state;
        this.setState(previousState => ({
            checked: {
              ...previousState.checked,
                [index]: !previousState.checked[index]
            }
        }));

        var selecetdCheckboxes = [];
        this.setState({
            selecetdCheckboxes: selecetdCheckboxes.push(id)
        })
        let checkedStatus = false;
        var elements = document.querySelectorAll('.selectContacts');
        for(let ele of elements){
            if(ele.checked){
                checkedStatus = true;
            } else {
                ele.checked = false;
            }
        }
        this.setState({
            sendContactChk: checkedStatus
        });

        if (e.target.checked) {
            let obj = {};
            obj['id'] =  id;
            obj['index'] =  index;
            obj['val'] = e.target.value;
            this.state.selectedContacts.push(obj)
            if (this.state.selectedContacts.length > 4) {
                swal("", "You can not select more than 5 participant!", "error");
            }
        } else {
            // var index = this.state.selectedContacts.indexOf(e.target.value);
            let allContactArray = this.state.selectedContacts;
            allContactArray.map((data, i)=> data.val === e.target.value ? allContactArray.splice(i, 1) : []);
            this.setState({
                selectedContacts: allContactArray
            })
        }
        this.setState({
            sendContactChk: this.state.selectedContacts.length
        })
    }

    /*Function to send messages to multiple contacts */
    forwardMessage = (selectedContacts, message, type, fileName, fileSize, fileType) => {
        this.setState({ forwardMessageToContacts: false });
        message = encrypt(message);
        selectedContacts.forEach((contact) => {
            const chatListRef = NODES.getChatListReference(contact.id);
            chatListRef.once("child_added", (snapshot) => {
                var chatData = snapshot.val();
                if(chatData.isBlockedByMe === "1" || chatData.amIBlocked === "1"){
                    console.log("blocked")
                } else {
                    console.log("unblocked")
                    this.props.getUserMessageCount(this.props.userId, contact.id);
                    this.props.sendMessageProcess(contact.id, message, type, fileName, fileSize, fileType);
                }
            })
        })
    }

    render() {
        const {registeredUsers, search, selectedContacts, checked, message, type, fileName, fileSize, fileType} = this.state;
        
        const checkedCount = Object.keys(checked).filter(key => checked[key]).length;
        // console.log(checkedCount)

        const disabled = checkedCount > 4 ;

        //Search contact from contact listing
        const contactUsers = registeredUsers !== undefined && registeredUsers.filter(users => {
            return users.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
        
        return (
            <div>
                <Modal className="ForwardMessageToContacts" show={this.state.forwardMessageToContacts} onHide={this.handleClose} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title> 
                            <span>Forward message to </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="chatL_filter">
                            <div className="search_newchat">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"></path></svg>
                                <input type="text" name="search_newChat" onKeyUp={this.onKeyDown} className="search_newChat" placeholder="Search contact"  onChange={this.onChangeSearch} value={search} />
                                {this.state.closeSearch ? <svg onClick={()=> this.setState({search: '', closeSearch: false }) } className="s-close-btn" height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg> : null  }
                            </div>
                        </div>
                        <div className="contact-listing many-forward">
                            <div>CONTACTS</div>
                            <ul>
                                {contactUsers !== undefined && contactUsers.length ? contactUsers.map((contactUser, i) => {
                                    if(contactUser.alredyAdded) {
                                        return(
                                            <li key={i}>
                                                <label className="custom_check">
                                                    <input type="checkbox" disabled={contactUser.alredyAdded} checked={contactUser.alredyAdded} ref="check_me" onChange={(e)=> this.selectContacts(e, i, contactUser.id)}  className="selectContacts" value={contactUser.name} data-id={contactUser.id} disabled={!checked[i] && disabled}/>
                                                    <span className="checkmark"></span>
                                                </label> 
                                                <span className="userdetails">
                                                    <span className="userimg">
                                                        <img onError={this.addDefaultUserImg} onDragStart={(e) => { e.preventDefault() }} src={contactUser.imageUrl && contactUser.imageUrl !== "" ? contactUser.imageUrl : user__xs} alt="user__xs" />
                                                    </span>
                                                    <span className="username">
                                                        <p className="name" data-id={contactUser.id}>{contactUser.name}</p>
                                                        <p className="about-user">Blocked</p>
                                                    </span>
                                                </span>
                                            </li>
                                        )
                                    } else {
                                        return(
                                            <li key={i}>
                                                <label className="custom_check">
                                                    <input type="checkbox" checked={checked[i] || false} ref="check_me" onChange={(e)=> this.selectContacts(e, i, contactUser.id)}  className="selectContacts" value={contactUser.name} data-id={contactUser.id} disabled={!checked[i] && disabled}/>
                                                    <span className="checkmark"></span>
                                                </label> 
                                                <span className="userdetails">
                                                    <span className="userimg">
                                                        <img onError={this.addDefaultUserImg} onDragStart={(e) => { e.preventDefault() }} src={contactUser.imageUrl && contactUser.imageUrl !== "" ? contactUser.imageUrl : user__xs} alt="user__xs" />
                                                    </span>
                                                    <span className="username">
                                                        <p className="name" data-id={contactUser.id}>{contactUser.name}</p>
                                                        <p className="about-user">{contactUser.about}</p>
                                                    </span>
                                                </span>
                                                
                                            </li>
                                        )
                                    }  
                                }) : 
                                    <div className="users__item">
                                        <p className="no-search-message">
                                            <span>No contacts found!!</span>
                                        </p>
                                    </div>
                                }
                            </ul>
                            {
                                this.state.uploadingStarted ? <CsLoader/> : ''
                            }
                        </div>
                        {this.state.sendContactChk ? 
                            <div className="send-div forward-div">
                                <span>{selectedContacts !== undefined && selectedContacts.length ? selectedContacts.map(contacts => contacts.val).join(", ") : ''}</span>
                                <div className="send-icn" onClick={() => this.forwardMessage(selectedContacts, message, type, fileName, fileSize, fileType)}>
                                    <svg width="535.5px" height="535.5px" viewBox="0 0 535.5 535.5">
                                        <g>
                                            <g id="send">
                                                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75 "/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div> 
                        : ""
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
export default ForwardMessageToContacts;
