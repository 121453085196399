/* Node references in this file */
/* Initiated by diyu (19/02/2021) */

import db from '../config/firebase'


export const Users = 'users';
export const ADMINS = 'Admins';
export const UserState = 'userState';
export const UserStateWeb = 'userStateWeb';
export const BLOCK_USERS = 'blockUsers';
export const USER_MESSAGES = 'user-messages';
export const REGISTERED_USERS = 'registeredUser';
export const MESSAGES = 'message';
export const CHATLIST = 'ChatListNew';
export const notifications = 'notifications';
export const GROUP = 'Group';
export const GROUP_PARTICIPANTS = 'Particiapnts';
export const GROUP_MESSAGES = 'GroupMessage';
export const EXIT_GROUP_USERS = 'ExitUsersGroup';
export const WALLPAPER = 'wallpaper';
export const CHAT_WALLPAPERS = 'chatWallpapers';
export const PROFILE_PICTURE = 'profilePicture';
export const GROUP_ICON = 'groupPicture';
export const MESSAGE_IMAGES = 'message_images';
export const MESSAGE_DOCUMENTS = 'message_documents';
export const AUDIO_MESSAGES = 'audio_message';
export const ADMIN_PROFILE_IMAGES = 'admin_profile_images';

export const ADMIN_DISPLAY_NAME = 'admin_display_name';

// Users node reference
export const getUsers = () => {
    return db.database().ref(Users);
}
// Users node reference with userId
export const getUserReference = (userId) => {
    return db.database().ref(Users).child(userId);
}

// User mobile state node reference
export const getUserActiveStateReference = (userId) => {
    return db.database().ref(Users).child(userId).child(UserState);
}

// User web state node reference
export const getUserStateReference = (userId) => {
    return db.database().ref(Users).child(userId).child(UserStateWeb);
}

// Registered users node reference
export const getRegisteredUserReference = (userId) => {
    return db.database().ref(REGISTERED_USERS).child(userId);
}

// Registered users node reference with sender and receiver
export const getRegisteredUserWithSenderAndReceiverReference = (senderId, receiverId) => {
    return db.database().ref(REGISTERED_USERS).child(senderId).child(receiverId);
}

// Blocked users node reference
export const getBlockedUsersReference = (userId) => {
    return db.database().ref(BLOCK_USERS).child(userId);
}

// Block users node reference with sender and receiver
export const getBlockUserReference = (senderId, receiverId) => {
    return db.database().ref(BLOCK_USERS).child(senderId).child(receiverId);
}

// Wallpaper node reference
export const getWallpaperReference = (userId) => {
    return db.database().ref(WALLPAPER).child(userId);
}

// chat list node reference
export const getChatListReference = (userId) => {
    return db.database().ref(CHATLIST).child(userId);
}

// chat list node reference with sender and receiver/groupId
export const getChatListUserReference = (senderId, receiverId) => {
    return db.database().ref(CHATLIST).child(senderId).child(receiverId);
}

//notifications reference
export const getNotificationReference = (senderId, receiverId) => {
    return db.database().ref(notifications).child(senderId).child(receiverId);
}

// Message node reference
export const getMessagesReference = () => {
    return db.database().ref(MESSAGES);
}

// Message key node reference
export const getMessageKeyReference = (messageKey) => {
    return db.database().ref(MESSAGES).child(messageKey);
}

// User message node reference
export const getUserMessagesReference = (senderId, receiverId) => {
    return db.database().ref(USER_MESSAGES).child(senderId).child(receiverId);
}

// User message key node reference
export const getUserMessageKeyReference = (senderId, receiverId, messageKey) => {
    return db.database().ref(USER_MESSAGES).child(senderId).child(receiverId).child(messageKey);
}

// Group node reference
export const getGroupNodeReference = () => {
    return db.database().ref(GROUP);
}

// Group node with id reference
export const getGroupReference = (groupId) => {
    return db.database().ref(GROUP).child(groupId);
}

// Get group participants reference
export const getGroupParticipantsReference = (groupId) => {
    return db.database().ref(GROUP).child(groupId).child(GROUP_PARTICIPANTS);
}

//Get group single participant reference
export const getGroupSingleParticipantReference = (groupId, userId) => {
    return db.database().ref(GROUP).child(groupId).child(GROUP_PARTICIPANTS).child(userId);
}

// Group message node reference
export const getGroupMessageReference = (groupId) => {
    return db.database().ref(GROUP_MESSAGES).child(groupId);
}

// Group message key reference
export const getGroupMessageKeyReference = (groupId, groupMessageKey) => {
    return db.database().ref(GROUP_MESSAGES).child(groupId).child(groupMessageKey);
}

// Exit group user node reference
export const getExitGroupUserReference = (userId, groupId) => {
    return db.database().ref(EXIT_GROUP_USERS).child(userId).child(groupId);
}

//Get exited user group participants
export const getExitGroupUserParticipantsReference = (userId, groupId) => {
    return db.database().ref(EXIT_GROUP_USERS).child(userId).child(groupId).child(GROUP_PARTICIPANTS);
}

//Get exited user group single participant
export const getExitGroupUserSingleParticipantsReference = (userId, groupId, participantId) => {
    return db.database().ref(EXIT_GROUP_USERS).child(userId).child(groupId).child(GROUP_PARTICIPANTS).child(participantId);
}

//Chat wallpaper reference
export const getChatWallpaperReference = () => {
    return db.storage().ref(CHAT_WALLPAPERS);
}

//Profile picture reference
export const getProfilePictureReference = (userId) => {
    return db.storage().ref(PROFILE_PICTURE).child(userId);
}

//Group icon reference
export const getGroupIconReference = (userId) => {
    return db.storage().ref(GROUP_ICON).child(userId);
}

//Message images reference
export const getMessageImagesReference = (userId) => {
    return db.storage().ref(MESSAGE_IMAGES).child(userId);
}

//Message documents reference
export const getMessageDocumentsReference = (userId) => {
    return db.storage().ref(MESSAGE_DOCUMENTS).child(userId);
}

//Audio messages reference
export const getAudioMessagesReference = (userId) => {
    return db.storage().ref(AUDIO_MESSAGES).child(userId);
}

// Admins node reference with userId
export const getAdminReference = () => {
    return db.database().ref(ADMINS);
}

//Admin profile images reference
export const getAdminProfileImageReference = (userId) => {
    return db.storage().ref(ADMIN_PROFILE_IMAGES).child(userId);
}
