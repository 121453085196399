import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setCurrentUser } from "../../actions/authActions";
import setAuthToken from "../../utils/setAuthToken";
// import SweetAlert from 'react-bootstrap-sweetalert';

const PrivateRoute = ({ 
	component: Component, 
	auth: { isAuthenticated },
	path
}) => {
	// const onConfirm = e =>
	// window.location.href = '/'
	return (
		<Route path={path} render={props => {
			if (!isAuthenticated) {
				// not logged in so redirect to login page with the return url
				return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
			}
			if (typeof localStorage.SW_jwtToken === 'undefined') {
				// Remove auth header for future requests
				setAuthToken(false);
				// Set current user to empty object {} which will set isAuthenticated to false
				setCurrentUser({});
				// Redirect to home page
				window.location.href = '/'
			}

			// authorised so return component
			return <Component {...props} />
		}} />
	)
}

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);