/* Controller for SendToContactModal*/
/* Initiated by kaya (13/10/2020) */
/* Modified by diyu (28/12/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
// import db from '../config/firebase'
// import user__xs from '../assets/img/user__xs.jpg';
// import download from 'image-downloader'

class ViewImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewImage: false,
            search: "",
            registeredUsers: [],
            selectedContacts: [],
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ viewImage: false });
    }

    /* Function to show contact modal */
    handleShow = (chatImage, userName, userType) => {
        this.setState({ viewImage: true, chatImage: chatImage, userName: userName });
    }

    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
      
    
    downloadImage = () => {
        var chatImage = this.state.chatImage.split('?');
        console.log(chatImage[0])
        this.toDataURL("https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0", function(dataUrl) {
            console.log('RESULT:', dataUrl)
            var anchor = document.getElementById("download_image"),
            href = anchor.href = dataUrl;
            console.log(href)
            
        })
    }

    render() {
        return (
            <Modal className="SendToContactModal view_image_modal ContactModal" show={this.state.viewImage} onHide={this.handleClose} size="lg">
                <Modal.Header className={this.state.userName !== "You" ? 'grey-header' : ''} closeButton>
                    <Modal.Title>
                        <span>{this.state.userName}</span>
                        {/* {this.state.userName !== "You" && */}
                            <div className="alignRight">
                                <a className="download_image" href={this.state.chatImage} download>
                                {/* <a className="download_image" id="download_image" download href="#" onClick={this.downloadImage}> */}
                                    <svg x="0px" y="0px" viewBox="0 0 512 512">
                                        <g>
                                            <path d="M240.915,420.418c0.004,0.004,0.008,0.007,0.012,0.011c0.493,0.492,1.012,0.959,1.551,1.402
                                            c0.247,0.203,0.508,0.379,0.762,0.57c0.303,0.227,0.6,0.462,0.915,0.673c0.304,0.203,0.619,0.379,0.931,0.565
                                            c0.286,0.171,0.565,0.35,0.859,0.507c0.318,0.17,0.645,0.314,0.97,0.467c0.306,0.145,0.608,0.298,0.922,0.428
                                            c0.315,0.13,0.637,0.236,0.957,0.35c0.337,0.121,0.669,0.25,1.013,0.354c0.32,0.097,0.645,0.168,0.969,0.249
                                            c0.351,0.089,0.698,0.187,1.056,0.258c0.375,0.074,0.753,0.118,1.13,0.172c0.311,0.044,0.618,0.104,0.933,0.135
                                            c1.4,0.138,2.811,0.138,4.211,0c0.315-0.031,0.622-0.09,0.933-0.135c0.377-0.054,0.755-0.098,1.13-0.172
                                            c0.358-0.071,0.705-0.169,1.056-0.258c0.323-0.081,0.649-0.152,0.969-0.249c0.344-0.104,0.677-0.233,1.013-0.354
                                            c0.32-0.115,0.642-0.22,0.957-0.35c0.314-0.13,0.616-0.283,0.922-0.428c0.325-0.153,0.652-0.297,0.97-0.467
                                            c0.294-0.157,0.573-0.337,0.859-0.507c0.312-0.186,0.627-0.362,0.931-0.565c0.315-0.211,0.612-0.446,0.915-0.673
                                            c0.254-0.19,0.515-0.367,0.762-0.57c0.539-0.443,1.058-0.909,1.551-1.402c0.004-0.004,0.008-0.007,0.012-0.011l149.333-149.333
                                            c8.331-8.331,8.331-21.839,0-30.17s-21.839-8.331-30.17,0L277.333,353.83V21.333C277.333,9.551,267.782,0,256,0
                                            s-21.333,9.551-21.333,21.333V353.83L121.752,240.915c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17L240.915,420.418z
                                            "/>
                                            <path d="M405.333,469.333H106.667c-11.782,0-21.333,9.551-21.333,21.333S94.885,512,106.667,512h298.667
                                            c11.782,0,21.333-9.551,21.333-21.333S417.115,469.333,405.333,469.333z"/>
                                            </g>
                                    </svg>Download
                                </a>
                            </div>
                        {/* } */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sendtoContact_listing">
                        <img onDragStart={(e) => { e.preventDefault() }} alt={this.state.userName} src={this.state.chatImage} />
                    </div>
                    
                        
                </Modal.Body>
            </Modal>
        )
    }
}
export default ViewImage;