import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
  // Link
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import setAdminAuthToken from "./utils/setAdminAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { setCurrentAdmin, logoutAdmin} from "./actions/adminAuthActions";
import { Provider, connect } from "react-redux";
import store from "./store";
import Header from "./component/header";
import ScanQr from "./component/scan-app";
// import AdminLogin from "./component/Admin/AdminLogin";
import AdminLogin from './pages/login';
import LayoutComponent from './component/Admin/Layout';
import ChatLayout from "./component/chat-layout";
import PrivateRoute from "./component/private-route/PrivateRoute";
import PrivateRouteAdmin from "./component/private-route/PrivateRouteAdmin";


// Check for token to keep admin logged in
if (localStorage.SW_jwtAdminToken) {
	// Set admin auth token header auth
	const adminToken = localStorage.SW_jwtAdminToken;
	setAdminAuthToken(adminToken);
	// Decode admin token and get admin info and exp
	const decodedAdmin = jwt_decode(adminToken);
	// Set admin and isAdminAuthenticated
	store.dispatch(setCurrentAdmin(decodedAdmin));
	// Check for expired admin token
	const currentTime = Date.now() / 1000; // to get in milliseconds
	if (decodedAdmin.exp < currentTime) {
		// Logout admin
		store.dispatch(logoutAdmin());
	}
}

// Check for token to keep user logged in
if (localStorage.SW_jwtToken) {
	// Set auth token header auth
	const token = localStorage.SW_jwtToken;
	setAuthToken(token);
	// Decode token and get user info and exp
	const decoded = jwt_decode(token);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));
	// Check for expired token
	const currentTime = Date.now() / 1000; // to get in milliseconds
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());
	}
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: null,
			currentAdmin: null
		};
		
		if (localStorage.SW_jwtAdminToken) {
			// Set auth token header auth
			const adminToken = localStorage.SW_jwtAdminToken;
			// Decode token and get user info and exp
			const decodedAdmin = jwt_decode(adminToken);
			localStorage.removeItem("SW_jwtToken")
			localStorage.removeItem("user")
			this.state = {
				currentAdmin: decodedAdmin
			};
		}
		
		if (localStorage.SW_jwtToken) {
			// Set auth token header auth
			const token = localStorage.SW_jwtToken;
			// Decode token and get user info and exp
			const decoded = jwt_decode(token);
			// localStorage.removeItem("SW_jwtAdminToken")
			// localStorage.removeItem("admin")
			this.state = {
				currentUser: decoded
			};
		}

	}

	render() {
		var firstSlash = window.location.pathname.search(/\//) + 1;
		var rePath = window.location.pathname.substr(0, firstSlash) + window.location.pathname.slice(firstSlash).replace(/\//g, '-');
		// console.log(window.location.pathname)
		const { currentUser, currentAdmin } = this.state;
		return (
			<Provider store={store}>
				<Router >
					<Suspense fallback={null}>
						<div>
							{rePath.match(/^\/chat/) ||  window.location.pathname === '/' ?
								<Header/> : ''
							}
							<Switch>
								<Route path="/" exact component={ScanQr} /> 
								<Route path="/admin" exact component={AdminLogin} />
								<Route path="/app" exact render={() => <Redirect to="/app/main"/>}/>
								{currentAdmin ?
									<LayoutComponent authAdmin={currentAdmin} dispatch={this.props.dispatch}/>
										// <PrivateRouteAdmin path="/app" authAdmin={currentAdmin} dispatch={this.props.dispatch} component={LayoutComponent}/>
									:
									""
								}
								{currentUser ?
									<PrivateRoute authd={currentUser} path='/chat' dispatch={this.props.dispatch} component={ChatLayout} />
									: 
									'' 
								}
							</Switch>
						</div>
					</Suspense>
				</Router>
			</Provider>
		);
	}
}

// export default App;
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isAdminAuthenticated: state.adminAuth.isAdminAuthenticated,
});

export default connect(mapStateToProps)(App);