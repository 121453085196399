/* Controller for image adding and crop */
/* Initiated by steve (21/09/2020) */
/* Modified by steve (28/09/2020) */

import React, { Fragment,Component } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CsLoader from "./loader";


class CropperComponent extends Component {
  	constructor(props){
		super(props)

		this.state = {
			image: props.currentUploadImg,
			cropData: '#',
			cropper: '',
			chosenImage: '',
			removeUploading: false,
		}
	}
	  
	/*Function to get cropped image */
  	getCropData = () => {
		this.setState({
			uploadingStarted: true
		})
    	const {cropper} = this.state;
		if (typeof cropper !== "undefined") {
			this.setState({
				cropData:  cropper.getCroppedCanvas().toDataURL(),
			},() => {
				this.props.onImageData(this.state.cropData);
			})
		}
	};

	/* Function to cancel uploading of image */
	cancelUploading = () => {
		this.props.onRemoveUploading();
		this.setState({
			removeUploading: true,
			uploadingStarted: false
		})
	}
	
	render() {
  		const {image} = this.state;
  		return (
    		<Fragment>
				<div className="cropper__image">
					<div className="cropper__inner">
						<div className="row">
							<div className="col-12 col-sm-6">
								<div className="cropper__area">
									<Cropper
										style={{ maxWidth: "100% ",width: "100%",marginBottom:"15px"}}
										aspectRatio={1}
										preview=".img-preview"
										src={image}
										viewMode={1}
										guides={true}
										minCropBoxHeight={10}
										minCropBoxWidth={10}
										background={false}
										responsive={true}
										autoCropArea={1}
										checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
										onInitialized={(instance) => {
											this.setState({
												cropper:instance
											})
										}}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-6" >
								<div className="previewPane">
									<div className="img-preview"
									style={{ width: "100%", maxWidth: "100%", minHeight: '400px', marginBottom:"15px",height: 'auto',overflow:'hidden' }}
									/>
								</div>
							</div>
							<div  className="col-12 col-sm-6">
								<button className="btn btn-first" onClick={this.cancelUploading}>
									Cancel
								</button>
								&nbsp;
								<button className="btn btn-first" onClick={this.getCropData}>
									Save Cropping
								</button>
							</div>
							<div  className="col col-sm-6 text-center">
								<h6 className="preview__panetxt">Preview Pane</h6>
							</div>
							{
								this.state.uploadingStarted ? <CsLoader/> : ''
							}
						</div>
					</div>
				</div>
    		</Fragment>
  		)
  	}
};

export default CropperComponent;