import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import App from './App';
import reducers from './reducers';
// import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const store = createStore(
	reducers,
	applyMiddleware(ReduxThunk)
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// ReactDOM.render(
	
//   	<BrowserRouter>
//         <App />
//   	</BrowserRouter>, document.getElementById('root')
//   // <React.StrictMode>
//   //   <App />
//   // </React.StrictMode>,
//   // document.getElementById('root')
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
serviceWorker.registerServiceWorker()