/* Controller for AddUserBlockModal*/
/* Initiated by diyu (26/10/2020) */
/* Modified by kaya (26/10/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
// import CsLoader from "./../loader";
// import user__xs from '../../assets/img/user__xs.jpg';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactform: false,
            
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ contactform: false });
    }

    /* Function to show contact modal */
    handleShow = () => {
        this.setState({ contactform: true });
    }

    /* Function to search contacts */
    onChangeSearch = e => {
        this.setState({ 
            search: e.target.value
        });
    };


    render() {

        return (
            <div>
                <Modal className="ContactForm" show={this.state.contactform} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title> 
                            <span>contact us</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       
                       hello
                    </Modal.Body>
                
                </Modal>
            </div>
            
        )
    }
}
export default ContactForm;