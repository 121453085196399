import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
// import db from '../../../config/firebase'
import s from "./ListGroup.module.scss";
import settingsIcon from "../../../../images/settings.svg";
import logoutIcon from "../../../../images/logout.svg";
import accountIcon from "../../../../images/account.svg";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import { setCurrentAdmin } from "../../../../actions/adminAuthActions";
import setAdminAuthToken from "../../../../utils/setAdminAuthToken";

class MessagesDemo extends React.Component {
	constructor(props) {
		super(props);
		this.doLogout = this.doLogout.bind(this);
	}

	/*Function to logout of admin */
    doLogout = () => {
		// Remove token from local storage
		localStorage.removeItem("SW_jwtAdminToken");
		localStorage.removeItem("admin");
		// Remove auth header for future requests
		setAdminAuthToken(false);
		// Set current admin to empty object {} which will set isAdminAuthenticated to false
		setCurrentAdmin({});
		window.location.href = "/admin";
	}

	settings = () => {
		this.props.history.push("/app");
	}

	accout = () => {
		this.props.history.push("/app/account");
	}

	render() {
		return (
			<ListGroup className={[s.listGroupAccount, "thin-scroll"].join(" ")}>
				<p className={`${s.listGroupTitleAccount}`}>{this.props.admin.name}</p>
				<p className={`${s.listGroupSubtitleAccount}`}>{this.props.admin.email}</p>
				{/* <ListGroupItem className={`${s.listGroupItemAccount} mt-3`} onClick={() => this.settings()}>
					<img src={settingsIcon} alt="settings" className={"mr-2"} /> Settings
				</ListGroupItem> */}
				<ListGroupItem className={`${s.listGroupItemAccount} mt-2`} onClick={() => this.accout()}>
					<img src={accountIcon} alt="settings" className={"mr-2"} /> Account
				</ListGroupItem>
				<ListGroupItem className={`${s.listGroupItemAccount} mt-2 mb-3`} onClick={() => this.doLogout()}>
					<img src={logoutIcon} alt="settings" className={"mr-2"} /> Log out
				</ListGroupItem>
			</ListGroup>
		);
	}
}

function mapStateToProps(store) {
    return {
        // sidebarOpened: store.navigation.sidebarOpened,
		// sidebarStatic: store.navigation.sidebarStatic,
		isAdminAuthenticated: store.adminAuth.isAdminAuthenticated,
		admin: store.adminAuth.admin,
    };
}

export default withRouter(connect(mapStateToProps)(MessagesDemo));
