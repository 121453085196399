import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dismissAlert } from "../../../actions/alerts";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup";
import {
  changeActiveSidebarItem
} from "../../../actions/navigation";
import lightDashboardIcon from "../../../images/light-dashboard.svg";
import darkDashboardIcon from "../../../images/dark-dashboard.svg";
import lightTables from "../../../images/tables.svg";
import darkTables from "../../../images/tables-dark.svg";
// import form from "../../../images/ui-elements.svg";
import lightTypography from "../../../images/Typography.svg";
import darkTypography from "../../../images/Typography-dark.svg";
// import logo from "../../../assets/img/logo-icon.png";
import logo from "../../../assets/img/logo-icon11.png";

class Sidebar extends React.Component {
	static propTypes = {
		sidebarStatic: PropTypes.bool,
		sidebarOpened: PropTypes.bool,
		dispatch: PropTypes.func.isRequired,
		activeItem: PropTypes.string,
		location: PropTypes.shape({
			pathname: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		sidebarStatic: true,
		sidebarOpened: true,
		activeItem: ""
	};

	// constructor(props) {
	// 	super(props);
	// }

	dismissAlert(id) {
		this.props.dispatch(dismissAlert(id));
	}

	render() {
		return (
			<div className={`${(!this.props.sidebarOpened && !this.props.sidebarStatic ) ? s.sidebarClose : ''} ${s.sidebarWrapper}`} id={"sidebar-drawer"}>
			<nav className={s.root}>
				<header className={s.logo}>
					<img src={logo} alt="logo" className={s.logoStyle} />
					<span>Kiveez&nbsp;</span>
				</header>
				<ul className={s.nav}>
					<LinksGroup
					onActiveSidebarItemChange={activeItem =>
						this.props.dispatch(changeActiveSidebarItem(activeItem))
					}
					activeItem={this.props.activeItem}
					header="Dashboard"
					isHeader
					link="/app/main/dashboard"
					index="main"
					>
					{window.location.href.includes("dashboard") ? (
						<img
						src={darkDashboardIcon}
						alt="lightDashboard"
						width={"24px"}
						height={"24px"}
						/>
					) : (
						<img
						src={lightDashboardIcon}
						alt="lightDashboard"
						width={"24px"}
						height={"24px"}
						/>
					)}
					</LinksGroup>
					<LinksGroup
					onActiveSidebarItemChange={activeItem =>
						this.props.dispatch(changeActiveSidebarItem(activeItem))
					}
					activeItem={this.props.activeItem}
					header="Users List"
					isHeader
					link="/app/users"
					index="main"
					>
					{window.location.href.includes("users") ? (
						<img
						src={darkTables}
						alt="lightDashboard"
						width={"24px"}
						height={"24px"}
						/>
					) : (
						<img
						src={lightTables}
						alt="lightDashboard"
						width={"24px"}
						height={"24px"}
						/>
					)}
					</LinksGroup>
					<LinksGroup
					onActiveSidebarItemChange={activeItem =>
						this.props.dispatch(changeActiveSidebarItem(activeItem))
					}
					activeItem={this.props.activeItem}
					header="Reports"
					isHeader
					link="/app/reports"
					index="main"
					>
					{window.location.href.includes("reports") ? (
						<img
						src={darkTypography}
						alt="lightDashboard"
						width={"24px"}
						height={"24px"}
						/>
					) : (
						<img
						src={lightTypography}
						alt="lightDashboard"
						width={"24px"}
						height={"24px"}
						/>
					)}
					</LinksGroup>
				</ul>
			</nav>
		</div>
		);
	}
}

function mapStateToProps(store) {
	return {
		// sidebarOpened: store.navigation.sidebarOpened,
		// sidebarStatic: store.navigation.sidebarStatic,
		// alertsList: store.alerts.alertsList,
		// activeItem: store.navigation.activeItem,
		// navbarType: store.navigation.navbarType,
		isAdminAuthenticated: store.adminAuth.isAdminAuthenticated,
		admin: store.adminAuth.admin,
	};
}

export default withRouter(connect(mapStateToProps)(Sidebar));
