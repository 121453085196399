/* Controller for contactModal*/
/* Initiated by diyu (01/12/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import CsLoader from "./../loader";
import swal from 'sweetalert';
import user__xs from '../../assets/img/user__xs.jpg';

class AddParticipantsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactModal: false,
            search: "",
            registeredUsers: [],
            selectedContacts: [],
            sendContactChk: false,
            closeSearch: false,
            matched: false,
            selecetdCheckboxes: [],
            checked: {}
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ addParticipantsModal: false, selectedContacts: [], sendContactChk: false, checked: {} });
    }

    /* Function to show contact modal */
    handleShow = (groupParticipantsWithRegisteredUsers, groupId) => {
        this.setState({ addParticipantsModal: true, groupParticipantsWithRegisteredUsers: groupParticipantsWithRegisteredUsers, groupId: groupId, closeSearch: false, search: "", selectedContacts: [], sendContactChk: false, checked: {} });
    }
    
    /* Function to search contacts */
    onChangeSearch = (e) => {
        this.setState({ 
            search: e.target.value
        });
    };

    /*Function to select contact to send */
    selectParticipants = (e, index, url, tagLine, id, fullName, phoneNumber) => {
        // const {selecetdCheckboxes}=this.state;
        this.setState(previousState => ({
            checked: {
              ...previousState.checked,
                [index]: !previousState.checked[index]
            }
        }));

        var selecetdCheckboxes = [];
        this.setState({
            selecetdCheckboxes: selecetdCheckboxes.push(id)
        })
        let checkedStatus = false;
        var elements = document.querySelectorAll('.selectParticipants');
        for(let ele of elements){
            if(ele.checked){
                checkedStatus = true;
            } else {
                ele.checked = false;
            }
        }
        this.setState({
            sendContactChk: checkedStatus
        });

        if (e.target.checked) {
            let obj = {};
            obj['img'] =  url;
            obj['tagLine'] =  tagLine;
            obj['id'] =  id;
            obj['fullName'] =  fullName;
            obj['phoneNumber'] =  phoneNumber;
            obj['index'] =  index;
            obj['val'] = e.target.value;
            this.state.selectedContacts.push(obj)
            if (this.state.selectedContacts.length > 4) {
                swal("", "You can not select more than 5 participant!", "error");
            }
        } else {
            // var index = this.state.selectedContacts.indexOf(e.target.value);
            let allContactArray = this.state.selectedContacts;
            allContactArray.map((data, i)=> data.val === e.target.value ? allContactArray.splice(i, 1) : []);
            this.setState({
                selectedContacts: allContactArray
            })
        }
        this.setState({
            sendContactChk: this.state.selectedContacts.length
        })
    }
  
    /*Function to search and clear if close is clicking on search */
    onKeyDown = (e) => {
        let val = e.target.value;
        this.setState({
            closeSearch : val !== '' ? true : false
        })
         
    }

    addDefaultUserImg = (ev) => {
        ev.target.src = user__xs;
    }

    /*Function to remove participants from selection */
    removeParticipants = (contact, i) => {
        this.setState(previousState => ({
            checked: {
              ...previousState.checked,
                [contact.index]: !previousState.checked[contact.index]
            }
        }));
        let contactList = this.state.selectedContacts,
            index = contactList.indexOf(contact);

        if (index > -1) {
            contactList.splice(index, 1);
            this.setState({
                selectedContacts: contactList
            })          
        }
        let checkBoxList =  document.querySelectorAll('.contact-listing  input[type="checkbox"]');
        for(let checkBox of  checkBoxList) {
            if(checkBox.value === contact.val) {
                checkBox.checked = false;
            }
        }

        this.setState({
            sendContactChk: this.state.selectedContacts.length
        })
        
    }

    /*Function to add participants */
    addParticipants = () => {
        this.setState({ addParticipantsModal: false });
        this.state.selectedContacts.forEach((contact) => {
            this.props.updateGroupEncryptionMessage(contact.id, this.state.groupId);
        })
        this.props.addGroupParticipants(this.state.selectedContacts)
    }

    render() {
        const {groupParticipantsWithRegisteredUsers, search, selectedContacts, checked} = this.state;
        
        const checkedCount = Object.keys(checked).filter(key => checked[key]).length;
        // console.log(checkedCount)

        const disabled = checkedCount > 4 ;

        //Search contact from contact listing
        const contactUsers = groupParticipantsWithRegisteredUsers !== undefined && groupParticipantsWithRegisteredUsers.filter(users => {
            return users.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
        
        return (
            <div>
                <Modal className="AddParticipantsModal" show={this.state.addParticipantsModal} onHide={this.handleClose} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title> 
                            <span>Add participant</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="chatL_filter">
                            <div className="search_newchat">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"></path></svg>
                                <input type="text" name="search_newChat" onKeyUp={this.onKeyDown} className="search_newChat" placeholder="Search contact"  onChange={this.onChangeSearch} value={search} />
                                {this.state.closeSearch ? <svg onClick={()=> this.setState({search: '', closeSearch: false }) } className="s-close-btn" height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg> : null  }
                            </div>
                        </div>
                        {this.state.sendContactChk ? 
                            <div className="customBadgeList p-2">
                                {/* {selectedContacts !== undefined && selectedContacts.length ? selectedContacts.map(contacts => contacts).join(", ") : ''} */}
                                    {selectedContacts !== undefined && selectedContacts.length ? selectedContacts.map((contacts , i) => 
                                        <span className="badge badge-primary d-inline-flex align-items-center" key={i}>
                                            <img onError={this.addDefaultUserImg} onDragStart={(e) => { e.preventDefault() }} className="badgeProfile mr-2" src={contacts.img ? contacts.img : user__xs} alt={contacts.val}/>
                                            {contacts.val}
                                            <i onClick={() => this.removeParticipants(contacts, i)} className="fa fa-times ml-1"></i>
                                        </span>
                                    ) : ''}
                            </div>
                        : 
                            ""
                        }
                        <div className="contact-listing">
                            <div>CONTACTS</div>
                            <ul>
                                {contactUsers !== undefined && contactUsers.length ? contactUsers.map((contactUser, i) => {
                                    if(contactUser.alredyAdded) {
                                        return(
                                            <li key={i} className="listDisabled">
                                                <label className="custom_check">
                                                    <input type="checkbox" disabled={contactUser.alredyAdded} checked={contactUser.alredyAdded} ref="check_me" onChange={(e)=>this.selectParticipants(e, i, contactUser.imageUrl, contactUser.tag_line, contactUser.id, contactUser.fullName, contactUser.phoneNumber
                                                        )} className="selectParticipants" value={contactUser.name} data-id={contactUser.id}/>
                                                    <span className="checkmark"></span>
                                                </label> 
                                                <span className="userdetails">
                                                    <span className="userimg">
                                                        <img onError={this.addDefaultUserImg} onDragStart={(e) => { e.preventDefault() }} src={contactUser.imageUrl && contactUser.imageUrl !== "" ? contactUser.imageUrl : user__xs} alt="user__xs" />
                                                    </span>
                                                    <span className="username">
                                                        <p className="name" data-id={contactUser.id}>{contactUser.name}</p>
                                                        <p className="about-user">Already Added to group</p>
                                                    </span>
                                                </span>
                                            </li>
                                        )
                                    } else {
                                        return(
                                            <li key={i}>
                                                <label className="custom_check">
                                                    <input type="checkbox" checked={checked[i] || false} ref="check_me" onChange={(e)=> this.selectParticipants(e, i, contactUser.imageUrl, contactUser.tag_line, contactUser.id, contactUser.fullName, contactUser.phoneNumber)}  className="selectParticipants" value={contactUser.name} data-id={contactUser.id} disabled={!checked[i] && disabled}/>
                                                    <span className="checkmark"></span>
                                                </label> 
                                                <span className="userdetails">
                                                    <span className="userimg">
                                                        <img onError={this.addDefaultUserImg} onDragStart={(e) => { e.preventDefault() }} src={contactUser.imageUrl && contactUser.imageUrl !== "" ? contactUser.imageUrl : user__xs} alt="user__xs" />
                                                    </span>
                                                    <span className="username">
                                                        <p className="name" data-id={contactUser.id}>{contactUser.name}</p>
                                                        <p className="about-user">{contactUser.about}</p>
                                                    </span>
                                                </span>
                                                
                                            </li>
                                        )
                                    }     
                                }) : 
                                    <div className="users__item">
                                        <p className="no-search-message">
                                            <span>No contacts found!!</span>
                                        </p>
                                    </div>
                                }
                            </ul>
                            {
                                this.state.uploadingStarted ? <CsLoader/> : ''
                            }
                        </div>
                        {this.state.sendContactChk ? 
                            <div className="send-div part-send-div">
                                <div className="send-icn" onClick={this.addParticipants}>
                                    {/* <svg width="32" height="32" viewBox="0 0 32 32">
                                        <polygon points="13 24 4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24" />
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#fca722" stroke="none">
                                            <path d="M4473 4540 c-126 -26 -51 43 -1423 -1326 l-1275 -1274 -480 480 c-518 518 -529 527 -657 551 -51 9 -79 9 -124 1 -113 -23 -158 -53 -306 -200 -114 -115 -142 -149 -170 -207 -30 -64 -33 -78 -33 -165 0 -83 4 -103 29 -160 28 -61 77 -113 799 -837 556 -557 784 -779 821 -800 71 -39 168 -39 241 -1 38 20 432 409 1606 1585 1481 1483 1557 1561 1585 1623 25 57 29 77 29 160 0 137 -27 191 -176 345 -126 130 -187 179 -257 205 -59 22 -154 31 -209 20z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div> 
                        : ""
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
export default AddParticipantsModal;
