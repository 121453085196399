import React, { useEffect, useState } from 'react'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Pie} from 'react-chartjs-2';
import { Container, Row, Col, Card} from "react-bootstrap";

export default function Platform(props) {
    const [pstartDate, setStartDate] = useState(props.startDate);
    const [pendDate, setEndDate] = useState(props.endDate);
    const [reports, setReports] = useState([]);
    const [chartdata, setChartData] = useState([]);
    const [labels, setLabels] = useState([]);

    if(pstartDate !== props.startDate){
        setStartDate(props.startDate);
        setEndDate(props.endDate);
    
    }

    const columns = [{
        dataField: 'id',
        text: 'Id'
      }, {
        dataField: 'plateform',
        text: 'Plateform',
      }, {
        dataField: 'activeUsers',
        text: 'Active Users'
    }];

 const  loadReportsFromServer = (startDate, endDate, dimensionName, metricsName) => {
       // this.setState({ loading: true })
       let reportData = {
           startDate: startDate,
           endDate: endDate,
           dimensionName: dimensionName,
           metricsName: metricsName
       }
       axios.post(`${process.env.REACT_APP_API_URL}/api/reports/generate`, reportData)
       .then(result => {
           let reportsData = [];
           let datachart= [];
           let labelsdata= [];
           let response = result.data;
           if (response.success) {
               if(response.reports) {
                   let reports = response.reports ? response.reports[0].rows : "";
                   // console.log(reports)
                   if(reports.length) {
                       reports.forEach((report, i) => {
                        //    report = {
                        //        id: i+1,
                        //        plateform: report.dimensionValues[0].value,
                        //        activeUsers: report.metricValues[0].value,
                        //    }       
                           labelsdata.push(report.dimensionValues[0].value) ;
                           datachart.push(report.metricValues[0].value) ;
                        // reportsData.push(report)

                       });
                   }
                //    setReports(reportsData); // report state.
                   setLabels(labelsdata); // set chart label state
                   setChartData(datachart); // set chart data state
               }
           }
       })
       .catch(error => {
           // this.setState({
           // 	preLoader: false,
           // 	loader: false
           // });
           console.log(error);
       });
   };


   useEffect(() => {
       let startDate = pstartDate,
           endDate = pendDate,
           dimensionName = "platform",
           metricsName = "activeUsers";
       loadReportsFromServer(startDate, endDate, dimensionName, metricsName);
   }, [pstartDate, pendDate]);

 

   const data = {
        labels : labels,
        datasets : [
            {
                label: 'Active Users',
                data : chartdata,
                backgroundColor: [
                    'rgb(153, 204, 255)',
                    'rgb(164, 227, 250)',
                    'rgb(218, 222, 223)'
                  ],
          hoverOffset: 4

            },
        ]
    }

   return (
       <>
            {/* <BootstrapTable 
				keyField='id' 
				data={ reports } 
				columns={ columns } 
				pagination={ paginationFactory() } 
			/> */}
           <Pie data = {data} width={50} height={27}></Pie>
       </>
   )
}
