/* Controller for AddUserBlockModal*/
/* Initiated by diyu (26/10/2020) */
/* Modified by kaya (26/10/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import CsLoader from "./../loader";
import user__xs from '../../assets/img/user__xs.jpg';

class AddUserBlockModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addUserBlockModal: false,
            search: "",
            registeredUsers: [],
            selectedContacts: [],
            sendContactChk: false,
            closeSearch: false,
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ addUserBlockModal: false });
    }

    /* Function to show contact modal */
    handleShow = (userId) => {
        console.log(this.props)
        var registeredUsers = this.props.registeredUsers;
        this.setState({ addUserBlockModal: true, registeredUsers: registeredUsers, userId: userId });
    }

    /* Function to search contacts */
    onChangeSearch = e => {
        this.setState({ 
            search: e.target.value
        });
    };

    /*Function to search and clear if close is clicking on search */
    onKeyDown = (e) => {
        let val = e.target.value;
        this.setState({
            closeSearch : val !== '' ? true : false
        })
         
    }

    blockContact = (senderId, receiverId) => {
        this.props.confirmBlockUser(senderId, receiverId)
        this.setState({ addUserBlockModal: false });
    }

    render() {
        const {registeredUsers, search, selectedContacts, userId} = this.state;

        //Search contact from contact listing
        const contactUsers = registeredUsers.filter(users => {
            return users.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });

        return (
            <div>
                <Modal className="ContactModal" show={this.state.addUserBlockModal} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title> 
                            <span>Add blocked contact</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="chatL_filter">
                            <div className="search_newchat">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"></path></svg>
                                <input type="text" name="search_newChat" onKeyUp={this.onKeyDown} className="search_newChat" placeholder="Search contact" onChange={this.onChangeSearch} value={search} />
                                {this.state.closeSearch ? <svg onClick={()=> this.setState({search: '', closeSearch: false }) } className="s-close-btn" height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg> : null  }
                            </div>
                        </div>
                        <div className="contact-listing">
                            <ul>
                                {contactUsers.length ? contactUsers.map((contactUser, i) =>
                                    <li key={i} onClick={() => this.blockContact(userId, contactUser.id)}>
                                        <span className="userdetails">
                                        <span className="userimg">
                                            <img onDragStart={(e) => { e.preventDefault() }} src={contactUser.imageUrl && contactUser.imageUrl !== "" ? contactUser.imageUrl : user__xs} alt="user__xs" />
                                        </span>
                                        <span className="username">
                                            <p className="name" data-id={contactUser.id}>{contactUser.name}</p>
                                            <p className="about-user">{contactUser.about}</p>
                                        </span>
                                        </span>
                                    </li>
                                ) : ""}
                            </ul>
                            {
                                this.state.uploadingStarted ? <CsLoader/> : ''
                            }
                        </div>
                        {this.state.sendContactChk ? 
                            <div className="send-div">
                                <span>{selectedContacts !== undefined && selectedContacts.length ? selectedContacts.map(contacts => contacts).join(", ") : ''}</span>
                                <div className="send-icn" onClick={() => this.refs.sendToContactModal.handleShow() ||  this.handleClose()}>
                                    <svg width="535.5px" height="535.5px" viewBox="0 0 535.5 535.5">
                                        <g>
                                            <g id="send">
                                                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75 "/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div> 
                        : ""
                        }
                    </Modal.Body>
                
                </Modal>
            </div>
            
        )
    }
}
export default AddUserBlockModal;