/* Controller for SendToContactModal*/
/* Initiated by kaya (13/10/2020) */
/* Modified by kaya (13/10/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import user__xs from '../assets/img/user__xs.jpg';

class ViewContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewContact: false,
            search: "",
            registeredUsers: [],
            selectedContacts: [],
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ viewContact: false });
    }

    /* Function to show contact modal */
    handleShow = (userName, userPhoneNumber, userImage, userType, contactName) => {
        this.setState({ viewContact: true, userName: userName, userPhoneNumber: userPhoneNumber, userImage: userImage, contactName: contactName });
    }

    /*Function to click user and redirect on chat */
    showMessage = (event) => {
        event.preventDefault();
        this.setState({ viewContact: false });
        this.props.selectUserToChat();
    }

    render() {

        return (
            <Modal className="SendToContactModal view_contact_modal ContactModal" show={this.state.viewContact} onHide={this.handleClose} size="lg">
                <Modal.Header className={this.state.contactName !== "You" ? 'grey-header' : ''} closeButton>
                    <Modal.Title>
                    <span> View contact  "{this.state.userName}" </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sendtoContact_listing">
                        <ul>
                            <li>
                                <p>
                                    <img  onDragStart={(e) => { e.preventDefault() }} alt={this.state.userName} src={this.state.userImage ? this.state.userImage : user__xs} />
                                    <span className="username">{this.state.userName}</span>
                                </p>
                                <p className="contact_details">
									<span className="phone_number">{this.state.userPhoneNumber}</span>
                                    {/* <span className="file_icn" onClick={this.showMessage}>
                                        <svg width="32px" height="32px" viewBox="0 0 32 32"><path d="M17.74,30,16,29l4-7h6a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2H6A2,2,0,0,0,4,8V20a2,2,0,0,0,2,2h9v2H6a4,4,0,0,1-4-4V8A4,4,0,0,1,6,4H26a4,4,0,0,1,4,4V20a4,4,0,0,1-4,4H21.16Z" transform="translate(0 0)"></path><rect x="8" y="10" width="16" height="2"></rect><rect x="8" y="16" width="10" height="2"></rect><rect className="cls-1" width="32" height="32"></rect></svg>
                                    </span> */}
								</p>
								{/* <p className="contact_details"><span className="phone_link"><a href="">TEL</a></span></p> */}
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default ViewContact;