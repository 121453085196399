
const firebase = require('firebase');

const firebaseConfig = {
    apiKey: "AIzaSyBmCz2i4L3fygjnzCagklYvNH43eMbQ1U8",
    authDomain: "social-app-11703.firebaseapp.com",
    databaseURL: "https://social-app-11703.firebaseio.com",
    projectId: "social-app-11703",
    storageBucket: "social-app-11703.appspot.com",
    messagingSenderId: "178387870822",
    appId: "1:178387870822:web:bc54ab724385d5deebf9a7",
    measurementId: "G-20HTT3B23G"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// module.exports = firebase;
export default firebase;