/* Controller for setting option*/
/* Initiated by steve (29/09/2020) */
/* Modified by diyu (02/11/2020) */

import React, { Component, Fragment }  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import chat_ion from '../assets/img/kypc.png';
import AddUserBlockModal from "./modal/AddUserBlockModal";
import ContactForm from "./modal/ContactForm";
import user__xs from '../assets/img/user__xs.jpg';

class SettingOpt extends Component{
    constructor(props) {
        super(props);
        this.state = {
            backgroundWallpaper: "",
            isActive : false
        }
        // console.log(props)
    }

    /*Function to show blocked users*/
    chatL__blockedUsers = (event) => {
        event.preventDefault();
        document.querySelector('.chatL_slideItem').classList.remove('active');
        document.querySelector('.chatL__blockedUsers').classList.add('active');
    }
    
    /*Function to change wallpaper*/
    chatL__Wallpaper = (event) => {
        event.preventDefault();
        document.querySelector('.chatL_slideItem').classList.remove('active');
        document.querySelector('.chatL__Wallpaper').classList.add('active');
    }
    
    /*Function for Help page*/
    help__Page = (event) => {
        event.preventDefault();
        document.querySelector('.chatL_slideItem').classList.remove('active');
        document.querySelector('.help__Page').classList.add('active');
    }

    /*Function to change wallpaper colors*/
    onChangeWallpaper = (event) => {
        var $target = event.target;
        var backgroundWallpaper = $target.closest('li').querySelector('img').src;
      
        $target.parentElement.childNodes.forEach((li, i)=>{
            li.classList.remove('active')
        });
        $target.closest('li').classList.add('active');

        this.setState({ backgroundWallpaper: backgroundWallpaper });
        this.props.setWallpaper(backgroundWallpaper)
    };

    onHoverChangeWallpaper = (event) => {
        var $target = event.target;
        var backgroundWallpaper = $target.closest('li').querySelector('img').src;
        this.setState({ backgroundWallpaper: backgroundWallpaper });
        this.props.setWallpaperHover(backgroundWallpaper)
    };

    onHoverRemoveWallpaper = (event) => {
        var backgroundWallpaper = "";
        this.setState({ backgroundWallpaper: backgroundWallpaper });
        this.props.setWallpaperHover(backgroundWallpaper)
    };
   
   
    render() {
        const {blockedUserArray, chatWallpapers} = this.props;
        return (
            <Fragment>
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="profile__setting">
                            <div className="chatLP__header">
                                <div className="clp__title">
                                    <span>
                                        <svg onClick={this.props.goProfileBack} width="32px" height="32px" viewBox="0 0 32 32"><polygon points="13 26 14.41 24.59 6.83 17 29 17 29 15 6.83 15 14.41 7.41 13 6 3 16 13 26" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32" /></svg>
                                    </span>Profile
                                </div>
                            </div>
                            <div className="chatLP__body">
                                <div className="aboutUser__tab">
                                    <span>
                                        <img  onDragStart={(e) => { e.preventDefault() }} src={this.props.userImage ? this.props.userImage : user__xs} alt="user__xs" />
                                    </span>
                                    <span>
                                        <span>{this.props.userName ? this.props.userName : ""}</span>
                                        <span>{this.props.userAbout ? this.props.userAbout : ""}</span>
                                    </span>
                                </div>
                                <div className="setting__option" onClick={this.chatL__Wallpaper}>
                                    <span>
                                        <svg  viewBox="0 0 24 24"  width="45" height="45"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 4h7V2H4c-1.1 0-2 .9-2 2v7h2V4zm6 9l-4 5h12l-3-4-2.03 2.71L10 13zm7-4.5c0-.83-.67-1.5-1.5-1.5S14 7.67 14 8.5s.67 1.5 1.5 1.5S17 9.33 17 8.5zM20 2h-7v2h7v7h2V4c0-1.1-.9-2-2-2zm0 18h-7v2h7c1.1 0 2-.9 2-2v-7h-2v7zM4 13H2v7c0 1.1.9 2 2 2h7v-2H4v-7z"/></svg>
                                        Chat Wallpaper
                                    </span>
                                </div>
                                <div className="setting__option" onClick={this.chatL__blockedUsers}>
                                    <span>
                                        <svg viewBox="0 0 24 24" width="45" height="45"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"/></svg>
                                        Blocked
                                    </span>
                                </div>
                                <div className="setting__option" onClick={this.help__Page}>
                                    <span>
                                        <svg viewBox="0 0 24 24" width="45" height="45"><path d="M0 0h24v24H0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg>
                                        Help
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chatL_slideItem chatL__blockedUsers">
                    <div className="chatLP__header">
                        <div className="clp__title">
                            <span>
                                <svg onClick={this.props.goProfileBack} width="32px" height="32px" viewBox="0 0 32 32"><polygon points="13 26 14.41 24.59 6.83 17 29 17 29 15 6.83 15 14.41 7.41 13 6 3 16 13 26" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32" /></svg>
                            </span>Blocked Contacts
                        </div>
                    </div>
                    <div className="chatLP__body">
                        <div className="chatL_users">
                            {/* <p className="add-blocked-user" onClick={() => this.refs.addUserBlockModal.handleShow(this.props.auth.user.id)}>
                                <svg  x="0px" y="0px" viewBox="0 0 469.333 469.333" >
                                    <path d="M298.667,277.333c-56.853,0-170.667,28.48-170.667,85.333v42.667h341.333v-42.667
                                        C469.333,305.813,355.52,277.333,298.667,277.333z"/>
                                    <path d="M298.667,234.667c47.147,0,85.333-38.293,85.333-85.333C384,102.187,345.813,64,298.667,64s-85.333,38.187-85.333,85.333
                                        C213.333,196.373,251.52,234.667,298.667,234.667z"/>
                                    <polygon points="170.667,192 106.667,192 106.667,128 64,128 64,192 0,192 0,234.667 64,234.667 64,298.667 106.667,298.667 
                                        106.667,234.667 170.667,234.667"/>
                                </svg>
                                Add blocked contact
                            </p> */}
                            <ul>
                                {blockedUserArray !== undefined && blockedUserArray.length ? blockedUserArray.map((user, i) =>
                                    <li key={i}>
                                        <div className="users__item" onClick={() => this.props.confirmUnBlockUser(this.props.userId, user.receiverId)}>
                                            <div className="users__icon">
                                                <img  onDragStart={(e) => { e.preventDefault() }} src={user.receiverImage ? user.receiverImage : user__xs} alt={user.receiverName ? user.receiverName : user__xs} />
                                            </div>
                                            <div className="user__detail">
                                                <div className="userD_top">
                                                    <span className="userD__name">
                                                        <span>{user.receiverName}</span>
                                                    </span>
                                                </div>
                                                <div className="userD_bottom">
                                                    <span className="userD__msg"><span>{user.receiverAbout}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                ) :
                                <div className="blocked_user_section">
                                    <div className="blocked_user_section-inner">
                                        <span> 
                                            <svg x="0px" y="0px" viewBox="0 0 472.615 472.615">
                                                <circle cx="147.692" cy="98.47" r="68.923"/>
                                                <path d="M271.266,200.647c-10.266-9.228-22.454-16.234-36.014-20.319l-15.854-4.629l-67.946,67.947l-68.045-67.947l-15.854,4.629
                                                    C27.178,192.539,0,228.974,0,271.12v83.407h211.226c-11.629-47.005,0.726-98.784,37.39-135.448
                                                    C255.6,212.093,263.203,205.959,271.266,200.647z"/>
                                                <path d="M436.618,233.005c-23.253-23.253-54.16-36.053-87.039-36.053c-32.878,0-63.786,12.8-87.039,36.053
                                                    c-47.995,47.996-47.995,126.082,0,174.078c23.994,23.993,55.517,35.985,87.039,35.985c31.523,0,63.046-11.992,87.039-35.985
                                                    C484.614,359.087,484.614,281.001,436.618,233.005z M246.182,320.044c0-27.619,10.751-53.584,30.283-73.115
                                                    c19.531-19.531,45.495-30.283,73.114-30.283c24.309,0,47.327,8.345,65.814,23.659L269.842,385.856
                                                    C254.527,367.369,246.182,344.352,246.182,320.044z M422.694,393.157c-37.953,37.954-98.327,40.137-138.912,6.608L429.317,254.23
                                                    c15.315,18.487,23.659,41.505,23.659,65.814C452.976,347.662,442.224,373.627,422.694,393.157z"/>
                                            </svg>
                                        </span>  
                                        <p>No blocked contacts yet</p>
                                        <p>Blocked contacts will no longer be able to call you or send you message</p>
                                    </div>
                                </div>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="chatL_slideItem chatL__Wallpaper">
                    <div className="chatLP__header">
                        <div className="clp__title">
                            <span>
                                <svg onClick={this.props.goProfileBack} width="32px" height="32px" viewBox="0 0 32 32"><polygon points="13 26 14.41 24.59 6.83 17 29 17 29 15 6.83 15 14.41 7.41 13 6 3 16 13 26" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32" /></svg>
                            </span>Chat Wallpaper
                        </div>
                    </div>
                    <div className="chatLP__body wallpaper-section">
                        <ul className="colors_section">
                            {(chatWallpapers.length && chatWallpapers !== undefined) ? chatWallpapers.map((wallpaper, i) => 
                                <li key={i} onClick={this.onChangeWallpaper} className="" onMouseOver={this.onHoverChangeWallpaper} onMouseOut={this.onHoverRemoveWallpaper}>
                                    <img  onDragStart={(e) => { e.preventDefault() }} src={wallpaper} alt="wallpaper"/>
                                </li>
                            ) : ''}
                        </ul>
                    </div>
                </div>
                <div className="chatL_slideItem help__Page">
                    <div className="chatLP__header">
                        <div className="clp__title">
                            <span>
                                <svg onClick={this.props.goProfileBack} width="32px" height="32px" viewBox="0 0 32 32"><polygon points="13 26 14.41 24.59 6.83 17 29 17 29 15 6.83 15 14.41 7.41 13 6 3 16 13 26" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32" /></svg>
                            </span>Help
                        </div>
                    </div>
                    <div className="chatLP__body help-section">
                        <div className="social_icn">
                            <img  onDragStart={(e) => { e.preventDefault() }} src={chat_ion} alt="chat_ion" />
                        </div>
                        <ul>
                            <li>FAQ</li>
                            {/* <li onClick={() => this.refs.contactform.handleShow()}>Contact us</li> */}
                            <li>Contact us</li>
                            <li>Terms and Privacy Policy</li>
                        </ul>
                    </div>
                </div>
                <AddUserBlockModal ref={'addUserBlockModal'} registeredUsers={this.props.registeredUsers} confirmBlockUser={this.props.confirmBlockUser}/>
                <ContactForm ref={'contactform'} />
            </Fragment>
        )
    }
}

// export default SettingOpt;
SettingOpt.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default (connect(
    mapStateToProps
)(SettingOpt));