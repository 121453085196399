/* Controller for Quotation */
/* Initiated by diyu (23/09/2021) */
/* Modified by diyu (23/09/2021) */

import React , { Component } from "react";
import { Alert, Button, Label} from "reactstrap";
import user__xs from '../../assets/img/user__xs.jpg';
import swal from 'sweetalert';
import * as NODES from '../../constants/nodes';
import { v4 as uuid } from 'uuid';
import {Helmet} from "react-helmet";

class Account extends Component {

  	constructor(props) {
		super(props);

		this.state = {
			name: '',
            email: '',
            imageUrl: '',
			quotationError: '',
		};
  	}

	componentDidMount() {
        const admin = JSON.parse(localStorage.getItem("admin"))
		this.setState({
            userId: admin.uId,
            name: admin.name,
            email: admin.email,
            imageUrl: admin.imageUrl,
            status: admin.status,
        });
	}

	/*Function to show default user image if image is missing or broken */
    addDefaultUserImg(ev) {
        ev.target.src = user__xs;
    }
	
	/*Function to on change and get values from input */
	onChangeUserName = (e) => {
			const id = uuid();
			const usernameRef = NODES.getAdminReference();
			var updateData = {
				name: e.target.value
			}
			usernameRef.update(updateData)
	};

	/* Function to load file with on change event */
    onFileChange = (e) => {
        let fileData = e.target.files[0],
            fileName = fileData.name,
            fileSize = fileData.size,
            fileType = fileData.type;
        var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0; while (fileSize > 900) { fileSize /= 1024; i++; }
        fileSize = (Math.round(fileSize * 100) / 100) + ' ' + fSExt[i];
        fileSize = String(fileSize);
        // let filePath = this.uploadChatDocs.value;
        let audioVideoX = /(audio\/|video\/)/;
        let docX = /(image\/)/;
        let fileTypes = fileName.split(/\.(?=[^\.]+$)/)[1];
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
		if (!docX.test(files[0].type)) {
			swal({
				title: "Invalid file type",
				text: 'Please select an image file.',
				icon: "warning",
				defaultMode: true,
				button: {
					text: "OK",
					value: true,
					visible: true,
					className: "default-btn",
					closeModal: true
				}
			})
			this.setState({
				chatUploadUploadOptActive: false,
			})
			this.uploadChatDocs.value = null;
			return false;
		} else if (files[0].size > 3e6) {
			// window.alert("Please upload a file smaller than 3 MB");
			swal({
				title: "Larger file size.",
				text: 'Please upload a file smaller than 3 MB.',
				icon: "warning",
				defaultMode: true,
				button: {
					text: "OK",
					value: true,
					visible: true,
					className: "default-btn",
					closeModal: true
				}
			})
			this.uploadChatDocs.value = null;
			return false;
		} else {
			//go to uploading//
			e.preventDefault();
			const reader = new FileReader();
			reader.onload = () => {
				this.setState({
					updatedImage: reader.result,
					uploadingStarted: true,
				})
				this.updateProfileImages(this.state.updatedImage, fileName)
			};
			reader.readAsDataURL(files[0]);
		}
	}

	/*Function to update admin profile image */
    updateProfileImages = async (documentData, fileName) => {
        var extension = fileName.split(/\.(?=[^\.]+$)/)[1];
        if (documentData !== '') {
            const id = uuid();
            const storageRef = NODES.getAdminProfileImageReference(id + '.' + extension);
            await storageRef.putString(documentData, 'data_url');
            storageRef.getDownloadURL().then((url) => {
                console.log(url)
                const adminsRef = NODES.getAdminReference();
				var updateData = {
					imageUrl: url
				}
				adminsRef.update(updateData)
				this.setState({
					imageUrl: url
				});
            });
        }
    }


  	render() {
		const { name, email, imageUrl } = this.state;
    	return (
			<div>
				<Helmet>
                    <title>Kiveez - Admin Profile</title>
                </Helmet>	
				<div className="form-group">
					<Label for="search-input1">User Name</Label>
						<input
							maxLength="50"
							className="form-control"
							onChange={this.onChangeUserName}
							type="text"
							required
							name="userName"
							placeholder="Enter user name"
							defaultValue={name}
						/>
				</div>
				<div className="form-group">
					<img onDragStart={(e) => { e.preventDefault() }} onError={this.addDefaultUserImg} src={imageUrl ? imageUrl : user__xs} alt="user__xs" />
				</div>
				<div className="form-group">
					<Label for="search-input1">Email</Label>
						<input 
							className="form-control"
							onChange={this.onChange}
							type="email"
							required
							name="email"
							placeholder="Enter email"
							defaultValue={email}
						/>
				</div>
				<div className="form-group">
					<Label for="search-input1">Admin Image</Label>
					<input className="form-control" type="file" id="uploadChatDocs" ref={uploadChatDocs => this.uploadChatDocs = uploadChatDocs} onChange={this.onFileChange} />
				</div>
      		</div>
    	);
  	}
}

export default Account;