import setAdminAuthToken from "../utils/setAdminAuthToken";

import { SET_CURRENT_ADMIN } from "./types";

// Set logged in user
export const setCurrentAdmin = decoded => {
	return {
		type: SET_CURRENT_ADMIN,
		payload: decoded
	};
};

// Log user out
export const logoutAdmin = () => dispatch => {
	// Remove token from local storage
	localStorage.removeItem("SW_jwtAdminToken");
	localStorage.removeItem("admin");
	// Remove auth header for future requests
	setAdminAuthToken(false);
	// Set current user to empty object {} which will set isAuthenticated to false
	dispatch(setCurrentAdmin({}));
};
