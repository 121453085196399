import React, { useState } from 'react'
import {Bar, Doughnut, Line} from 'react-chartjs-2';
import { Container, Row, Col, Card} from "react-bootstrap";
import {Pie} from 'react-chartjs-2';
import Platform from '../Platform/Platform';
import User  from '../Users/User';
import Model from '../devices/Model';
import Version from '../devices/Version';
import Location from '../location/Location';
import { FormGroup, Label, Button } from 'reactstrap';
import Gender from '../age_gender/Gender';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Age from '../age_gender/Age';
import Cohort from '../cohort/Cohort';

export default function Chart() {
    const currentDate = new Date();
    const days = 86400000 //number of milliseconds in a day
    const daysAgo = new Date(currentDate - (7*days))
	const start = daysAgo.getFullYear()+'-'+(daysAgo.getMonth()+1)+'-'+ (daysAgo.getDate());
    const end = (currentDate.getFullYear()+'-'+(currentDate.getMonth()+1)+'-'+(currentDate.getDate()-1));
    const initialStart = ((daysAgo.getMonth()+1)+'/'+(daysAgo.getDate())+'/'+daysAgo.getFullYear());
    const initialEnd = ((currentDate.getMonth()+1)+'/'+(currentDate.getDate()-1)+'/'+currentDate.getFullYear());

    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);

      const handleChange = (e) => {
        var date = new Date();	
        var days = 86400000 //number of milliseconds in a day
        var today = date.getDate();
        var month = date.getMonth()+1 ;
        var year = date.getFullYear();
		// var end = date.getFullYear()+'-'+(date.getMonth()+1) +'-'+();
		// var start = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+ (date.getDate()-1);

        if(e.target.value == 'today'){
            setStartDate(year+'-'+month+'-'+(today));
			setEndDate(year+'-'+month+'-'+(today));
            // console.log(start);
        }
        if(e.target.value == 'yesterday'){
            var daysAgo = new Date(date - (1*days))
		    var start = daysAgo.getFullYear()+'-'+(daysAgo.getMonth()+1)+'-'+ (daysAgo.getDate());
            setStartDate(start);
			setEndDate(start);
            // console.log(start);
        }
		if(e.target.value == 'this_week'){
            
			setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
			setEndDate(year+'-'+month+'-'+today);
		}
        if(e.target.value == 'last_week'){
            setStartDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
            setEndDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));            
		}
        if(e.target.value == 'last_7_days'){
			var daysAgo = new Date(date - (7*days))
		    var start = daysAgo.getFullYear()+'-'+(daysAgo.getMonth()+1)+'-'+ (daysAgo.getDate());
            setStartDate(start);
			setEndDate(year+'-'+month+'-'+(today-1));
            // console.log(start);
		}
        if(e.target.value == 'last_28_days'){
            var daysAgo = new Date(date - (28*days))
		    var start = daysAgo.getFullYear()+'-'+(daysAgo.getMonth()+1)+'-'+ (daysAgo.getDate());
            setStartDate(start);
			setEndDate(year+'-'+month+'-'+(today-1));
            // console.log(start);
        }
        if(e.target.value == 'last_30_days'){
            var daysAgo = new Date(date - (30*days))
		    var start = daysAgo.getFullYear()+'-'+(daysAgo.getMonth()+1)+'-'+ (daysAgo.getDate());
            setStartDate(start);
			setEndDate(year+'-'+month+'-'+(today-1));
            // console.log(start);
        }
        if(e.target.value == 'last_90_days'){
            var daysAgo = new Date(date - (90*days))
		    var start = daysAgo.getFullYear()+'-'+(daysAgo.getMonth()+1)+'-'+ (daysAgo.getDate());
            setStartDate(start);
			setEndDate(year+'-'+month+'-'+(today-1));
            // console.log(start);
        }
        if(e.target.value == 'last_12_months'){
            var date = moment().subtract(1, 'day').toDate();
            setStartDate(moment(date).subtract(12, 'months').format('YYYY-MM-DD'));
            setEndDate(moment(date).format('YYYY-MM-DD'));            
		}
        if(e.target.value == 'calender_year'){

            setStartDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
            setEndDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));           
		}
        if(e.target.value == 'this_year'){
            setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
            setEndDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));            
		}
       };
    const handleApply = (event,picker) => {
        // console.log(e);
        // console.log(picker.startDate.format("YYYY-MM-DD"));
        // console.log(picker.endDate.format("YYYY-MM-DD"));
        setStartDate(picker.startDate.format("YYYY-MM-DD"));
        setEndDate(picker.endDate.format("YYYY-MM-DD"));
    };

    return (
        <>
           <Container>
               <Row>
                   <Col md={4}>
                   {/* <Label for="search-input1">Select Date</Label> */}
							<select className="form-control" name="dateRange" onChange={handleChange}>
								<option value="" hidden>Select Date</option>
								<option value="today">Today</option>
								<option value="yesterday">Yesterday</option>
								<option value="this_week">This Week</option>
								<option value="last_week">Last Week</option>
								<option value="last_7_days">Last 7 days</option>
								<option value="last_28_days">Last 28 days</option>
								<option value="last_30_days">Last 30 days</option>
								<option value="last_90_days">Last 90 days</option>
								<option value="last_12_months">Last 12 months</option>
								<option value="calender_year">Last calender year</option>
								<option value="this_year">This year(Jan - Today)</option>
							</select>
                   </Col>
                   <Col md={4}>
                    <DateRangePicker
                    initialSettings={{ startDate: initialStart, endDate: initialEnd }}
                    onApply={handleApply}
                    >
                    <button className="btn btn-dark"><i class="fa fa-calendar" aria-hidden="true"></i> Custom Date</button>
                    </DateRangePicker>
                    {/* {console.log(moment().startOf('week').toDate())}
                    {console.log(moment().endOf('week').toDate())} */}
                   </Col>
               </Row>
                <Row  className="mt-2">
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">Device Model</Card.Title>
                                <Model startDate={startDate} endDate={endDate}/> {/* device model chart file  */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">OS Version</Card.Title>
                                <Version startDate={startDate} endDate={endDate}/> {/* os version chart file  */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">Platform</Card.Title>
                                <Platform startDate={startDate} endDate={endDate}/> {/* plateform chart file  */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">Location</Card.Title>
                                  <Location startDate={startDate} endDate={endDate}/> 
                                {/* <User/> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">Gender</Card.Title>
                                <Gender startDate={startDate} endDate={endDate}/> 
                                {/* gender chart file  */}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">Age</Card.Title>
                                <Age startDate={startDate} endDate={endDate}/> 
                                {/* Age chart file  */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={6}>
                        <User/>
                    </Col>
                    <Col md={6}>
                        <Card >
                            <Card.Body>
                                <Card.Title tag="h5">Cohort</Card.Title>
                                <Cohort/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
