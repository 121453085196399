import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Chart from "react-google-charts";
import axios from 'axios';
import { Container, Row, Col, Card} from "react-bootstrap";

const data = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700]
];

function Location(props) {
	const [pstartDate, setStartDate] = useState(props.startDate);
    const [pendDate, setEndDate] = useState(props.endDate);
	const [reports, setReports] = useState([]);
	const [chartdata, setChartData] = useState([]);
    const [labels, setLabels] = useState([]);

	if(pstartDate !== props.startDate){
        setStartDate(props.startDate);
        setEndDate(props.endDate);
    
    }
  const  loadReportsFromServer = (startDate, endDate, dimensionName, metricsName) => {
		// this.setState({ loading: true })
		let reportData = {
			startDate: startDate,
			endDate: endDate,
			dimensionName: dimensionName,
			metricsName: metricsName
		}
		axios.post(`${process.env.REACT_APP_API_URL}/api/reports/generate`, reportData)
		.then(result => {
			let reportsData = [];
			let datachart= [];
			datachart.push(['Country', 'Active User']);
			let labelsdata= [];
			let response = result.data;
			// console.log('device model')
			// console.log(response.reports)
			// console.log(response.reports[0].rows[1].dimensionValues[0].value)
			if (response.success) {
				if(response.reports) {
					let reports = response.reports ? response.reports[0].rows : "";
					// console.log(reports)
					if(reports.length) {
						reports.forEach((report, i) => {
						
							labelsdata.push(report.dimensionValues[0].value) ;
							datachart.push([report.dimensionValues[0].value,parseInt(report.metricValues[0].value)]) ;
							
						});
					}
                    setReports(reportsData); // report state.
					setLabels(labelsdata); // set chart label state
					setChartData(datachart); // set chart data state
				}
			}
		})
		.catch(error => {
			// this.setState({
			// 	preLoader: false,
			// 	loader: false
			// });
            console.log(error);
		});
	};

    useEffect(() => {
		let startDate = pstartDate,
			endDate = pendDate,
			dimensionName = "country",
			metricsName = "sessions";
		loadReportsFromServer(startDate, endDate, dimensionName, metricsName);
    }, [pstartDate, pendDate]);

    return (
      <>
        
        <Chart chartType="GeoChart" width="100%" height="200px" data={chartdata} />
      </>
    );

}
export default Location;
