import axios from "axios";

const setAdminAuthToken = token => {
    if (token) {
		// Apply authorization token to every request if logged in
		axios.defaults.headers.common["x-auth-token"] = token;
    } else {
		// Delete auth header
		delete axios.defaults.headers.common["x-auth-token"];
    }
};

export default setAdminAuthToken;