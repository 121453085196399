/* Controller for send link to contact*/
/* Initiated by diyu (24/12/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import CsLoader from "../loader";
import user__xs from '../../assets/img/user__xs.jpg';
import SendInviteGroupLink from "./SendInviteGroupLink";

class SendLinkToContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            registeredUsers: [],
            selectedContacts: [],
            sendContactChk: false,
            closeSearch: false
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ sendLinkToContacts: false });
    }

    /* Function to show contact modal */
    handleShow = () => {
        var registeredUsers = this.props.registeredUsers;
        this.setState({ sendLinkToContacts: true, registeredUsers: registeredUsers, search: "" });
    }

    /*Function to select contact to send */
    selectContacts = (e) => {
        let checkedStatus = false;
        var elements = document.querySelectorAll('.selectContacts');
        for(let ele of elements){
            if(ele.checked){
                checkedStatus = true;
            } else {
                ele.checked = false;
            }
        }
        this.setState({
            sendContactChk: checkedStatus
        });
        if (e.target.checked) {
            this.state.selectedContacts.push(e.target.value)
            console.log(this.state.selectedContacts)
        } else {
            var index = this.state.selectedContacts.indexOf(e.target.value);
            if (index > -1) {
                this.state.selectedContacts.splice(index, 1);
            }
            console.log(this.state.selectedContacts)
        }
    }
    
    /* Function to search contacts */
    onChangeSearch = (e) => {
        this.setState({ 
            search: e.target.value
        });
    };

    /*Function to send invite link to user*/
    sendInviteLink = (event, user) => {
        this.setState({
            sendLinkToContacts: false
        })
        this.props.selectUserToChat(event, user)
        this.props.sendInviteUserGroupLink(user)
    }

    render() {
        const {registeredUsers, search} = this.state;
        
        //Search contact from contact listing
        const contactUsers = registeredUsers.filter(users => {
            return users.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });

        return (
            <div>
                <Modal className="SendLinkToContacts" show={this.state.sendLinkToContacts} onHide={this.handleClose} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title> 
                            <span>Send link to</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="chatL_filter">
                            <div className="search_newchat">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"></path></svg>
                                <input type="text" name="search_newChat" onKeyUp={this.onKeyDown} className="search_newChat" placeholder="Search contact"  onChange={this.onChangeSearch} value={search} />
                                {this.state.closeSearch ? <svg onClick={()=> this.setState({search: '', closeSearch: false }) } className="s-close-btn" height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg> : null  }
                            </div>
                        </div>
                        <div className="contact-listing">
                            <ul>
                                {contactUsers.length ? contactUsers.map((contactUser, i) =>
                                    <li key={i} onClick={(event) => this.sendInviteLink(event, contactUser)}>
                                        {/* <label className="custom_check">
                                            <input type="checkbox"  ref="check_me" 
                                                onChange={(e)=>this.selectContacts(e, contactUser.imageUrl, contactUser.about, contactUser.id)} 
                                                className="selectContacts" value={contactUser.name} data-id={contactUser.id}/>
                                            <span className="checkmark"></span>
                                        </label>  */}
                                        <span className="userdetails">
                                            <span className="userimg">
                                                <img onDragStart={(e) => { e.preventDefault() }} src={contactUser.imageUrl && contactUser.imageUrl !== "" ? contactUser.imageUrl : user__xs} alt="user__xs" />
                                            </span>
                                            <span className="username">
                                                <p className="name" data-id={contactUser.id}>{contactUser.name}</p>
                                                <p className="about-user">{contactUser.about}</p>
                                            </span>
                                        </span>
                                        
                                    </li>
                                ) : 
                                    <div className="users__item">
                                        <p className="no-search-message">
                                            <span>No contacts found!!</span>
                                        </p>
                                    </div>
                                }
                            </ul>
                            {
                                this.state.uploadingStarted ? <CsLoader/> : ''
                            }
                        </div>
                        {this.state.sendContactChk ? 
                            <div className="send-div">
                                {/* <span>{selectedContacts !== undefined && selectedContacts.length ? selectedContacts.map(contacts => contacts).join(", ") : ''}</span> */}
                                <div className="send-icn" onClick={() => this.refs.sendInviteGroupLink.handleShow()}>
                                    <svg width="32" height="32" viewBox="0 0 32 32">
                                        <polygon points="13 24 4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24" />
                                    </svg>
                                </div>
                            </div> 
                        : ""
                        }
                    </Modal.Body>
                </Modal>
                <SendInviteGroupLink ref={'sendInviteGroupLink'} />
            </div>
        )
    }
}
export default SendLinkToContacts;
