/* Controller for Quotation */
/* Initiated by diyu (29/01/2021) */
/* Modified by diyu (29/01/2021) */

import React , { Component, Suspense  } from "react";
// import PropTypes from "prop-types";
// import { Alert, Button, Label} from "reactstrap";
import { FormGroup, Label, Button } from 'reactstrap';
import DatePicker from "reactstrap-date-picker";
import axios from 'axios';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Chart from './chart/Chart';
import {Helmet} from "react-helmet";

class Quotation extends Component {

	
  	constructor(props) {
		super(props);

		this.state = {
			reports: [],
			loading: false,
			indication: "No record found!",
			startDate: '',
			endDate: '',
			dimensionName: "country",
			metricsName: "sessions",
			compoStartDate:(new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+(new Date().getDate()-7)),
			compoEndDate : (new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+(new Date().getDate()-1)),
		};
  	}

	/* Function to load reports data from server */
	loadReportsFromServer = (startDate, endDate, dimensionName, metricsName) => {
		this.setState({ loading: true })
		let reportData = {
			startDate: startDate,
			endDate: endDate,
			dimensionName: dimensionName,
			metricsName: metricsName
		}
		axios.post(`${process.env.REACT_APP_API_URL}/api/reports/generate`, reportData)
		.then(result => {
			let reportsData = [];
			let response = result.data;
			// console.log('country')
			// console.log(response.reports[0].rows[0].dimensionValues[0].value)
			if (response.success) {
				if(response.reports) {
					let reports = response.reports ? response.reports[0].rows : "";
					// console.log(reports)
					if(reports.length) {
						reports.forEach((report, i) => {
							report = {
								id: i+1,
								country: report.dimensionValues[0].value,
								activeUsers: report.metricValues[0].value,
							}
							reportsData.push(report)
						});
					}
					this.setState({ 
						reports: reportsData,
						loading: false,
					});
				}
			}
		})
		.catch(error => {
			this.setState({
				preLoader: false,
				loader: false
			});
		});
	}

	componentDidMount() {
		let startDate = "2021-04-11",
			endDate = "2021-04-17",
			dimensionName = this.state.dimensionName,
			metricsName = this.state.metricsName;
		// this.loadReportsFromServer(startDate, endDate, dimensionName, metricsName);
	}
	
	/*Function to on change and get values from input */
	onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
	};

	searchReport = (e) => {
		// e.preventDefault();
		this.state.compoStartDate = this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : "2021-04-10";
		this.state.compoEndDate = this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : "2021-04-16";
		let startDate = this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : "2021-04-10",
			endDate = this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : "2021-04-16",
			dimensionName = this.state.dimensionName,
			metricsName = this.state.metricsName;
		console.log(startDate, endDate, dimensionName)
		// this.loadReportsFromServer(startDate, endDate, dimensionName, metricsName);
	}

	handleChange(value, formattedValue) {
		this.setState({
		  startDate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
		  formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
		})
	}
	dateChange(e){
		
		
	}
  	render() {
		const { reports, loading, startDate, endDate } = this.state;
		const columns = [{
			dataField: 'id',
			text: 'Id'
		  }, {
			dataField: 'country',
			text: this.state.dimensionName === 'city' ? 'City' : this.state.dimensionName === 'country' ? 'Country' : 'Country'
		  }, {
			dataField: 'activeUsers',
			text: 'Active Users'
		}];

    	return (
			<div
				className="my-auto"
			>
				<Helmet>
                    <title>Kiveez - Admin Reports</title>
                </Helmet>
				<h3 className="mt-0 mb-2" style={{ fontSize: 40 }}>
				Google Analytics Report
				</h3>
				{/* <div className='row'>
					<div className="form-group">
							<Label for="search-input1">Select Date</Label>
							<select className="form-control" name="dateRange" onChange={() => this.dateChange}>
								<option value="">Please Select</option>
								<option value="today">Today</option>
								<option value="yesterday">Yesterday</option>
								<option value="this_week">This Week</option>
								<option value="last_week">Last Week</option>
								<option value="last_28_days">Last 28 days</option>
								<option value="last_30_days">Last 30 days</option>
								<option value="last_90_days">Last 90 days</option>
								<option value="last_12_months">Last 12 months</option>
							</select>
						</div>
					</div>
				{ loading && <div>Loading ...</div>}
				<div className="row">
					<div className="form-group">
						<Label for="search-input1">Select Dimension</Label>
						<select className="form-control" name="dimensionName" onChange={this.onChange}>
							<option value="">Please Select</option>
							<option value="city">City</option>
							<option value="country">Country</option>
						</select>
					</div>
					<FormGroup>
						<Label>Start Date</Label>
						<DatePicker 
							value={startDate} 
							onChange={(date) => this.setState({ startDate: date })}
							maxDate={new Date().toISOString()}
							autoComplete="off"
						/>
					</FormGroup>
					<FormGroup>
						<Label>End Date</Label>
						<DatePicker 
							value={endDate} 
							onChange={(date) => this.setState({ endDate: date })}
							minDate={startDate}
							autoComplete="off"
						/>
					</FormGroup>
				</div>
				<Button color="warning" className="auth-btn mb-3" size="sm" onClick={this.searchReport}>Search</Button>
				<div className="table-responsive react-bootstrap-table table-bordered table-lg mt-lg mb-0 table">
					<BootstrapTable 
					keyField='id' 
					data={ reports } 
					columns={ columns } 
					pagination={ paginationFactory() } 
					noDataIndication={this.state.indication}
					/>
				</div> */}
					<Chart startDate={this.state.compoStartDate} endDate={this.state.compoEndDate}/>  {/* chart file  */}
			</div>
    	);
  	}
}

export default Quotation;