/* Controller for join group modal*/
/* Initiated by diyu (24/12/2020) */
/* Modified by diyu (25/12/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import users_img from '../../assets/img/users_img.png';
import db from '../../config/firebase'
import Button from 'react-bootstrap/Button';

class JoinGroupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            joinGroupModal: false,
            search: "",
            registeredUsers: [],
            selectedContacts: [],
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    addDefaultGroupIcon = (ev) => {
        ev.target.src = users_img;
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ joinGroupModal: false });
    }

    /* Function to show contact modal */
    handleShow = (userId, groupId) => {
        // console.log(userId, groupId)
        var groupParticipants = [];
        db.database().ref('Group').child(groupId).child("Particiapnts").on("child_added", (participants) => {
            var groupParticipant = participants.val();
            const userContactInfo = db.database().ref('users').child(groupParticipant.participant_id);
            userContactInfo.once("value", (contactInfo) => {
                var contactInformation = contactInfo.val();
                const chatListRef = db.database().ref('ChatListNew').child(groupParticipant.participant_id).child(groupId);
                chatListRef.once("value", (snapshot) => {
                    var chatData = snapshot.val();
                    if(chatData) {
                        if (groupParticipant && (chatData.isRemove === "0" || chatData.isRemove === "" || chatData.isRemove === null || chatData.isRemove === undefined) && (chatData.isExit === "0" || chatData.isExit === "" || chatData.isExit === null || chatData.isExit === undefined)) {
                            participants = {
                                participant_id: groupParticipant.participant_id,
                                participant_name: contactInformation.fullName,
                                participant_image: contactInformation.imageUrl,
                                participant_role: groupParticipant.participant_role,
                                tag_line: contactInformation.tag_line
                            }
                            groupParticipants.push(participants)
                            this.setState({
                                groupName: chatData.name,
                                groupDescription: chatData.description ? chatData.description : '',
                                groupImage: chatData.profilePic,
                                groupParticipants: groupParticipants,
                                groupId: groupId,
                                userId: userId
                            })
                        }
                    }
                })
            })
            this.setState({ joinGroupModal: true, userId: userId, groupId: groupId });
        });
    }

    /*Function to join group for user */
    joinGroupProcess = (userId, groupId, groupName, groupImage) => {
        this.props.updateGroupEncryptionMessage(userId, groupId);
        this.props.joinGroup(userId, groupId, groupName, groupImage)
        this.setState({ joinGroupModal: false });
    }

    render() {
        const {groupName, groupImage, userId, groupId} = this.state;

        return (
            <Modal className="joinGroupModal" show={this.state.joinGroupModal} onHide={this.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Join Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sendtoContact_listing">
                        <ul>
                            <li>
                                <p>
                                    <img onDragStart={(e) => { e.preventDefault() }} onError={this.addDefaultGroupIcon} alt={groupName} src={groupImage ? groupImage : users_img} />
                                    <span className="username">{groupName}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
                    <Button type="submit" variant="info" onClick={() => this.joinGroupProcess(userId, groupId, groupName, groupImage)}> Join Group</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default JoinGroupModal;