import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setCurrentAdmin } from "../../actions/adminAuthActions";
import setAdminAuthToken from "../../utils/setAdminAuthToken";
// import SweetAlert from 'react-bootstrap-sweetalert';

const PrivateRouteAdmin = ({ 
	component: Component, 
	adminAuth: { isAdminAuthenticated },
	path
}) => {
	// const onConfirm = e =>
	// window.location.href = '/'
	return (
		<Route exact path={path} render={props => {
			if (!isAdminAuthenticated) {
				// not logged in so redirect to login page with the return url
				return <Redirect to={{ pathname: '/admin', state: { from: props.location } }} />
			}
			if (typeof localStorage.SW_jwtAdminToken === 'undefined') {
				// Remove auth header for future requests
				setAdminAuthToken(false);
				// Set current admin to empty object {} which will set isAdminAuthenticated to false
				setCurrentAdmin({});
				// Redirect to home page
				window.location.href = '/admin'
			}

			// authorised so return component
			return <Component {...props} />
		}} />
	)
}

PrivateRouteAdmin.propTypes = {
	adminAuth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	adminAuth: state.adminAuth
});

export default connect(mapStateToProps)(PrivateRouteAdmin);