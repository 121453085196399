/* Controller for Login  */
/* Initiated by diyu (21/01/2021) */
/* Modified by diyu (04/02/2021) */


import React , { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button, Label, Input, FormGroup } from "reactstrap";
import Widget from "../../../src/component/Admin/Widget";
import { setCurrentAdmin } from "../../actions/adminAuthActions";
// import signinImg from "../../images/signinImg.svg";
import signinImg from "../../assets/img/main-banner.jpg";
import setAdminAuthToken from "../../utils/setAdminAuthToken";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import db from '../../config/firebase'
import {Helmet} from "react-helmet";

class Login extends Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
	};

  	constructor(props) {
		super(props);

		this.state = {
			email: '',
            password: '',
			rememberMe: false,
			loginError: '',
			showlogin:true,
			forgoterror:'',
			forgotSuccess: ''
		};
  	}

	componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.adminAuth.isAdminAuthenticated) {
            this.props.history.push("/app");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.adminAuth.isAdminAuthenticated) {
            const { from } = this.props.location.state || { from: { pathname: '/app' } } // retrieves the URL set on the PrivateRoute component
            window.location.href = from.pathname;
        }
    }

	/*Function to on change and get values from input */
	onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
	};
	
	/*Function to click on remember me and get value */
    rememberMe = (e) => {
        this.setState({
            rememberMe: e.target.checked
        })
    }
	
	/*Function to login from app */
	handleSubmit = (event) => {
		const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true });
        } else {
			this.setState({loader: true})
			event.preventDefault();
			const userData = {
                email: this.state.email,
                password: this.state.password,
                rememberMe: this.state.rememberMe
            };
            axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`, userData)
			.then(result => {
				// console.log(result.data)
				let response = result.data;
				if (response.success) {
					this.setState({ validated: true, preLoader: true });
					let adminDetails = response.profile;
					let token = response.token;
					// console.log(token)
					localStorage.setItem('admin', JSON.stringify(adminDetails));
					localStorage.setItem("SW_jwtAdminToken", token);
					// Set token to Auth header
					setAdminAuthToken(token);
					// Decode token to get user data
					const decoded = jwt_decode(token);
					// Set current user
					this.props.setCurrentAdmin(decoded);
				} else {
					event.preventDefault();
                    event.stopPropagation();
                    this.setState({ validated: false });
                    this.setState({
                        loginError: response.message,
						preLoader: false,
						loader:false
                    });
				}
			})
			.catch(error => {
                this.setState({
                    loginError: "Invalid email or password!",
					preLoader: false,
					loader: false
                });
            });
		}
	};

	forgotoptionshow=()=>{
		this.setState({
            showlogin: !this.state.showlogin
        })
	}

	forgotPassword = () => {
		db.auth().sendPasswordResetEmail(this.state.email)
		.then(function () {
			alert('Please check your email...');
		}).catch(function (e) {
			console.log(e.message);
			alert(e.message)
		})
	}

  	render() {
		const { loginError, showlogin, forgoterror, forgotSuccess } = this.state;
		const handleDismiss = () => this.setState({ loginError: '', forgoterror: '', forgotSuccess: '' });
    	return (
			<>
		
      		<div className="auth-page login-page row align-items-center m-0">
			  	<Helmet>
                    <title>Kiveez - Admin</title>
                </Helmet>
			  {
				(showlogin===true)?
				<div className="widget-auth my-auto col-md-6 col-lg-4">
					<h3 className="mt-0 mb-2" style={{ fontSize: 40 }}>
					Login
					</h3>
					<p className="widget-auth-info">
						Welcome Back! Please login to your account
					</p>
					{loginError && (
						<Alert className="alert-sm" color="danger" onClose={handleDismiss}>
							{loginError}
						</Alert>
					)}
					{forgotSuccess && (
						<Alert className="alert-sm" color="success" onClose={handleDismiss}>
							{forgotSuccess}
						</Alert>
					)}
					<form className="mt" onSubmit={this.handleSubmit}>
						<div className="form-group">
							<Label for="search-input1">Email</Label>
								<input
									className="form-control"
									onChange={this.onChange}
									type="email"
									required
									name="email"
									placeholder="Enter your email"
								/>
						</div>
						<div className="form-group mb-2">
							<Label for="search-input1">Password</Label>
							<input
								className="form-control"
								onChange={this.onChange}
								type="password"
								required
								name="password"
								placeholder="Enter your password"
							/>
						</div>
						<div className="row">
							<div className="col-6">
							<FormGroup className="checkbox abc-checkbox mb-4 d-flex" check>
							<Input
								id="checkbox1"
								type="checkbox"
								onChange={this.rememberMe}
							/>
							<Label for="checkbox1" check className={"mr-auto"}>
								Remember me
							</Label>
						</FormGroup>
							</div>
							<div className="col-6">
							
								<button type="button" onClick={this.forgotoptionshow} class="btn btn-link">Forget Password</button>
							</div>
						</div>

						
						
						<Button
						type="submit"
						color="warning"
						className="auth-btn mb-3"
						size="sm"
						>
							{this.props.isFetching ? "Loading..." : "Login"}
						</Button>
					</form>
				</div>:
			  
				<div className="widget-auth my-auto">
				 	<h3 className="mt-0 mb-2" style={{ fontSize: 40 }}>
					 Forgot Password
					</h3>
					{forgoterror && (
						<Alert className="alert-sm" color="danger" onClose={handleDismiss}>
							{forgoterror}
						</Alert>
					)}
					<form className="mt">
						<div className="form-group">
							<Label for="search-input1">Email</Label>
								<input
									className="form-control"
									onChange={this.onChange}
									type="email"
									required
									name="email"
									placeholder="Enter your email"
								/>
						</div>
						
						<Button
						type="button"
						color="warning"
						className="auth-btn mb-3"
						onClick={this.forgotPassword}
						size="sm"
						>
							{this.props.isFetching ? "Loading..." : " Forgot Password"}
						</Button>
						<Button
						type="submit"
						color="warning"
						className="auth-btn mb-3"
						onClick={this.forgotoptionshow}
						size="sm"
						>
							{this.props.isFetching ? "Loading..." : " Back"}
						</Button>
					</form>
				  
				</div>
					
				}
				
				<div className={"col-md-6 col-lg-8 img_warp"}>
					<img src={signinImg} alt="signin" className={"backImg"} />
				</div>
				
				
      		</div>
			</>
    	);
  	}
}

Login.propTypes = {
    setCurrentAdmin: PropTypes.func.isRequired,
    adminAuth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    adminAuth: state.adminAuth
});

export default (connect(
    mapStateToProps,
    { setCurrentAdmin }
)(Login));
