export const DISMISS_ALERT = 'DISMISS_ALERT';
export const SET_ALERT = "SET_ALERT";

export function dismissAlert(id) {
    return {
        type: DISMISS_ALERT,
        id,
    };
}
export const setAlert = (alertType, message, title = '', timeout = 3000) => dispatch => {
    dispatch({
        type: SET_ALERT,
        payload: { alertType, message, title, timeout }
    });
};