/* Controller for send invite link to user*/
/* Initiated by diyu (24/12/2020) */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class SendInviteGroupLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendInviteGroupLink: false,
            search: ""
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /* Function to close contact modal */
    handleClose = () => {
        this.setState({ sendInviteGroupLink: false });
    }

    /* Function to show contact modal */
    handleShow = () => {
        this.setState({ sendInviteGroupLink: true });
    }


    render() {

        return (
            <Modal className="SendToContactModal ContactModal" show={this.state.sendToContactModal} onHide={this.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> 
                        <span>Send link to</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sendtoContact_listing">

                    </div>
                    <div className="send-div">
                        <div className="send-icn">
                            <svg width="535.5px" height="535.5px" viewBox="0 0 535.5 535.5">
                                <g>
                                    <g id="send">
                                        <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75 "/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default SendInviteGroupLink;