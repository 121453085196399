/* Controller for setting option*/
/* Initiated by diyu (13/10/2020) */
/* Modified by diyu (15/10/2020) */

import React, { Component, Fragment }  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArchiveChatList from "./ArchiveChatList";

class Archived extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <Fragment>
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="profile__setting">
                            <div className="chatLP__header">
                                <div className="clp__title">
                                    <span>
                                        <svg onClick={this.props.goProfileBack} width="32px" height="32px" viewBox="0 0 32 32"><polygon points="13 26 14.41 24.59 6.83 17 29 17 29 15 6.83 15 14.41 7.41 13 6 3 16 13 26" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32" /></svg>
                                    </span>Archived Chats
                                </div>
                            </div>
                            <div className="chatLP__body">
                                <div className="chatL_users">
                                    <ArchiveChatList userId={this.props.userId} registeredUsers={this.props.registeredUsers} archiveChatListing = {this.props.archiveChatListing} selectUserToChat={this.props.selectUserToChat} activeUserId={this.props.activatedUserId} dropdownToggle={this.props.dropdownToggle} archiveUserChat={this.props.archiveUserChat} unMuteCurrentUser={this.props.unMuteCurrentUser} muteCurrentUser={this.props.muteCurrentUser} confirmDeleteChat={this.props.confirmDeleteChat} archiveGroupChat={this.props.archiveGroupChat} unMuteGroupChat={this.props.unMuteGroupChat} muteGroupChat={this.props.muteGroupChat} confirmExitGroup={this.props.confirmExitGroup} confirmDeleteGroup={this.props.confirmDeleteGroup} confirmDeleteGroupChat={this.props.confirmDeleteGroupChat} unArchiveUserChat={this.props.unArchiveUserChat} unArchiveGroupChat={this.props.unArchiveGroupChat} confirmBlockUser={this.props.confirmBlockUser} confirmUnBlockUser={this.props.confirmUnBlockUser} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

// export default SettingOpt;
Archived.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default (connect(
    mapStateToProps
)(Archived));