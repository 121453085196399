import React, { useEffect, useState } from 'react'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Pie, Doughnut, Bar} from 'react-chartjs-2';
import moment from 'moment';

export default function Cohort() {

     const [reports, setReports] = useState([]);
     const [chartdata, setChartData] = useState([]);
     const [labels, setLabels] = useState([]);
     const columns = [{
        dataField: 'id',
        text: 'Id'
      }, {
        dataField: 'browser',
        text: 'Browser',
      }, {
        dataField: 'activeUsers',
        text: 'Active Users'
    }];
  const  loadReportsFromServer = (startDate, endDate, cohort, cohortNthWeek, metricsName) => {
		// this.setState({ loading: true })
		let reportData = {
			startDate: startDate,
			endDate: endDate,
            cohort : cohort,
            cohortNthWeek : cohortNthWeek,
			metricsName: metricsName
		}
		axios.post(`${process.env.REACT_APP_API_URL}/api/cohortreport/generatecohort`, reportData)
		.then(result => {
			let reportsData = [];
            let datachart= [];
            let labelsdata= [];
			let response = result.data;
			console.log('cohorts')
			console.log(response.reports)
			// console.log(response.reports[0].rows[1].dimensionValues[0].value)
			if (response.success) {
				if(response.reports) {
					let reports = response.reports ? response.reports[0].rows : "";
					// console.log(reports)
					if(reports.length) {
						reports.forEach((report, i) => {
							// report = {
							// 	id: i+1,
							// 	browser: report.dimensionValues[1].value,
							// 	activeUsers: report.metricValues[0].value,
							// }
                            labelsdata.push(report.dimensionValues[1].value) ;
                            datachart.push(report.metricValues[0].value) ;
							// reportsData.push(report)
						});
					}
                    setReports(reportsData); // report state.
                    setLabels(labelsdata); // set chart label state
                    setChartData(datachart); // set chart data state
				}
			}
		})
		.catch(error => {
			// this.setState({
			// 	preLoader: false,
			// 	loader: false
			// });
            console.log(error);
		});
	};

    useEffect(() => {
        let startDate = "2021-03-07",
			endDate = "2021-03-13",
			cohort = "cohort",
            cohortNthWeek="cohortNthWeek",
			metricsName = "cohortActiveUsers";
		loadReportsFromServer(startDate, endDate, cohort, cohortNthWeek, metricsName);
    }, []);

    const today = moment();
    const from_date = today.startOf('week');
    const to_date = today.endOf('week');
    console.log({
        // thisweek_startdate: moment().startOf('week').toDate(),
        // thisweek_enddate: moment().endOf('week').toDate(),
        week1_startdate: moment().subtract(1,'week').startOf('week').format('YYYY-MM-DD'),
        week1_enddate: moment().subtract(1,'week').endOf('week').format('YYYY-MM-DD'),
        week2_startdate: moment().subtract(2,'week').startOf('week').format('YYYY-MM-DD'),
        week2_enddate: moment().subtract(2,'week').endOf('week').format('YYYY-MM-DD'),
        week3_startdate: moment().subtract(3,'week').startOf('week').format('YYYY-MM-DD'),
        week3_enddate: moment().subtract(3,'week').endOf('week').format('YYYY-MM-DD'),
        week4_startdate: moment().subtract(4,'week').startOf('week').format('YYYY-MM-DD'),
        week4_enddate: moment().subtract(4,'week').endOf('week').format('YYYY-MM-DD'),
        week5_startdate: moment().subtract(5,'week').startOf('week').format('YYYY-MM-DD'),
        week5_enddate: moment().subtract(5,'week').endOf('week').format('YYYY-MM-DD'),
        week6_startdate: moment().subtract(6,'week').startOf('week').format('YYYY-MM-DD'),
        week6_enddate: moment().subtract(6,'week').endOf('week').format('YYYY-MM-DD'),
    });
    
   const data = {
    labels : labels,
    datasets : [
        {
            label: 'Active Users',
            data : chartdata,
            backgroundColor: [
                'rgb(153, 204, 255)',
                'rgb(164, 227, 250)',
                'rgb(218, 222, 223)'
              ],
      hoverOffset: 4

        },
    ]
}
    return (
        <>
            <h1>This is cohort Report</h1>
            {/* <BootstrapTable 
					keyField='id' 
					data={ reports } 
					columns={ columns } 
					pagination={ paginationFactory() } 
			/> */}
           <Bar data = {data} width={50} height={27}></Bar>

        </>
    )
}
