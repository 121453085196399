/* Helper file for encryption */
/* Initiated by diyu (03/02/2021) */

// var CryptoJS = require("crypto-js");
const crypto = require('crypto')

let ENCRYPTION_KEY = "0366D8637F9C6B21";
// const ENCRYPTION_KEY = process.env.ENCRYPTION_KEY;

export const encrypt = (text) => {
    // The CipherIV methods must take the inputs as a binary / buffer values.
    // When creating the cipher in Node, we have to make sure we use the exact same
    // algorithm and inputs that we used in ColdFusion. In this case, we're using the "AES"
    // (Advanced Encryption Standard) with an "CBC" (Cipher Block Chaining) feedback mode
    // and 128-bit key.
    try {
        var cipher = crypto.createCipheriv( "AES-128-CBC", ENCRYPTION_KEY, ENCRYPTION_KEY );
    
        // When encrypting, we're converting the UTF-8 input to base64 output.
        var encryptedInput = (
            cipher.update( text, "utf8", "base64" ) +
            cipher.final( "base64" )
        );
        return encryptedInput;
    } catch (error) {
        console.log(error)
    }
}

export const decrypt = (text) => {
    try {
        var decipher = crypto.createDecipheriv( "AES-128-CBC", ENCRYPTION_KEY, ENCRYPTION_KEY );
        // When decrypting we're converting the base64 input to UTF-8 output.
        var decryptedInput = (
            decipher.update( text, "base64", "utf8" ) +
            decipher.final( "utf8" )
        );
        return decryptedInput;
    } catch (error) {
        console.log(error)
    }
}
