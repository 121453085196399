import React, {Component} from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

class AddEmoji extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setChosenEmoji: null,
        }
    }

    getSelectedEmoji = (emojiObject) => {
		// console.log(emojiObject)
        this.props.setChosenEmoji(emojiObject.native);
        this.setState({
            setChosenEmoji: emojiObject.native,
        })
    };
    
    render() {
        return (
            <div className="emojimart__wrapper">
                <Picker onSelect={emoji =>this.getSelectedEmoji(emoji)} style={{width:"100%"}} 
                    emoji={''} defaultSkin={1}
                    emojiTooltip={true} 
                    showSkinTones={true}
                    showPreview={true}
                    autoFocus={false}
                    title={''} notFoundEmoji={"NO result found!!"}
                />
                {/* <Picker onSelect={emoji =>this.getSelectedEmoji(emoji)} perLine={17} style={{width:"100%"}} 
                    emoji={''} defaultSkin={1}
                    emojiTooltip={true} showSkinTones={true}
                    showPreview={true}
                    emojiTooltip={true}
                    autoFocus={false}
                    title={''} notFoundEmoji={"NO result found!!"}
                /> */}
                {/* <Picker onSelect={this.addEmoji} emojiTooltip={true} onEmojiClick={this.onEmojiClick} /> */}
                {/* {this.state.showEmojis ? (
                    <span style={styles.emojiPicker} ref={el => (this.emojiPicker = el)}>
                        <Picker
                        onSelect={this.addEmoji}
                        emojiTooltip={true}
                        title="weChat"
                    />
                </span>
                ) : (
                <p style={styles.getEmojiButton} onClick={this.showEmojis}>
                    {String.fromCodePoint(0x1f60a)}
                </p>
                )} */}
            </div>
        );
    }
}

export default AddEmoji;