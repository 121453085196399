/* Controller for scan QR code */
/* Initiated by diyu (03/08/2020) */

import React, { Component, Fragment }  from "react";
import chat_ion from '../assets/img/scan_chat_img.svg';
import logo from '../assets/img/logo-icon.png';
import QRCode from "qrcode-react";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCurrentUser } from "../actions/authActions";
import setAuthToken from "../utils/setAuthToken";
import {Helmet} from "react-helmet";

class ScanQr extends Component{
    constructor(props) {
        super(props);
        this.state = {
            checked: 1,
            randomNumber: '',
            client_ip: '',
            errorMessage: ''
        }
    }

    /* Function to generate random numbers*/
    generateRandomNumber() {
        var length = "15";
    
        var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890!@#$%&*^";
        var randomNumber = "";
        for (var x = 0; x < length; x++) {
            var i = Math.floor(Math.random() * chars.length);
            randomNumber += chars.charAt(i);
        }
        this.setState({ randomNumber: randomNumber });

    }

    /* Render page data and set data on page load*/
    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to user chat
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/chat");
        } else if(localStorage.SW_jwtAdminToken) {
            this.props.history.push("/app");
        } else {
            this.loadSanData()
            setInterval(async () => {
                this.checkUserData()
            }, 15000);
        }
    }

    /* If logged in user is authenticated then redirect to chat with private route process*/
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            const { from } = this.props.location.state || { from: { pathname: '/chat' } } // retrieves the URL set on the PrivateRoute component
            window.location.href = from.pathname;
        }
    }

    /* Get system IP and add logged user data usin addScanData function*/
    loadSanData = () => {
        this.generateRandomNumber()
        fetch("https://api.ipify.org?format=json")
        .then(response => {
            return response.json();
        }, "jsonp")
        .then(res => {
            let response = res.ip;
            this.setState({ 
                client_ip: response
            });
            var data = {
                randomNumber: this.state.randomNumber,
                client_ip: this.state.client_ip,
                status: 'Pending'
            }
            // console.log(this.state)
            axios.post(`${process.env.REACT_APP_API_URL}/api/users/addScanData`, data)
            .then(res => {
                // console.log(res.data)
                let randomNumber = res.data.randomNumber;
                this.setState({ 
                    randomNumber: randomNumber
                });
                // console.log(this.state)
            })
            .catch(error => {
                console.log(error);
            });
        })
        .catch(err => console.log(err))
    }

    /* Function to check logged user data and save user data in local storage*/
    checkUserData = (e) => {
        const userData = {
            checked: this.state.checked,
            randomNumber: this.state.randomNumber
        };
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/getUserData`, userData)
        .then(result => {
            // console.log(result.data)
            let response = result.data;
            if (response.success) {
                let userDetails = response.profile;
                let token = response.token;
                localStorage.setItem('user', JSON.stringify(userDetails));
                localStorage.setItem("SW_jwtToken", token);
                // Set token to Auth header
                setAuthToken(token);
                // Decode token to get user data
                const decoded = jwt_decode(token);
                // Set current user
                this.props.setCurrentUser(decoded);
            } else {
                this.setState({
                    errorMessage: response.message,
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    /* If logged in user is checked for remeber me then it will be save and set checked value in state*/
    handleKeepLoggedIn=(event) => {
        console.log(event)
        console.log(event.target.checked)
        this.setState({
            checked: !this.state.checked
        })
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Kiveez - Web</title>
                </Helmet>
                <div className="scan-page container">
                    <div className="page__container">
                        <div className="scan__top">
                            <div className="row">
                                <div className="col-md-8 col-12">
                                    <div className="scan__qrdetail">
                                        <h4>To use Kiveez on your computer:</h4>
                                        <br/>  
                                        <p>1. Open Kiveez on your phone</p>
                                        <p>2. Tap <b>Menu</b> 
                                            <span className="menu__icon">
                                                <svg data-toggle="dropdown" version="1.1" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32"><circle cx="16" cy="8" r="2"></circle><circle cx="16" cy="16" r="2"></circle><circle cx="16" cy="24" r="2"></circle></svg>
                                            </span>
                                            or <b>Settings</b> 
                                            <span className="setting__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg"  width="32px" height="32px" viewBox="0 0 32 32"><path d="M27,16.76c0-.25,0-.5,0-.76s0-.51,0-.77l1.92-1.68A2,2,0,0,0,29.3,11L26.94,7a2,2,0,0,0-1.73-1,2,2,0,0,0-.64.1l-2.43.82a11.35,11.35,0,0,0-1.31-.75l-.51-2.52a2,2,0,0,0-2-1.61H13.64a2,2,0,0,0-2,1.61l-.51,2.52a11.48,11.48,0,0,0-1.32.75L7.43,6.06A2,2,0,0,0,6.79,6,2,2,0,0,0,5.06,7L2.7,11a2,2,0,0,0,.41,2.51L5,15.24c0,.25,0,.5,0,.76s0,.51,0,.77L3.11,18.45A2,2,0,0,0,2.7,21L5.06,25a2,2,0,0,0,1.73,1,2,2,0,0,0,.64-.1l2.43-.82a11.35,11.35,0,0,0,1.31.75l.51,2.52a2,2,0,0,0,2,1.61h4.72a2,2,0,0,0,2-1.61l.51-2.52a11.48,11.48,0,0,0,1.32-.75l2.42.82a2,2,0,0,0,.64.1,2,2,0,0,0,1.73-1L29.3,21a2,2,0,0,0-.41-2.51ZM25.21,24l-3.43-1.16a8.86,8.86,0,0,1-2.71,1.57L18.36,28H13.64l-.71-3.55a9.36,9.36,0,0,1-2.7-1.57L6.79,24,4.43,20l2.72-2.4a8.9,8.9,0,0,1,0-3.13L4.43,12,6.79,8l3.43,1.16a8.86,8.86,0,0,1,2.71-1.57L13.64,4h4.72l.71,3.55a9.36,9.36,0,0,1,2.7,1.57L25.21,8,27.57,12l-2.72,2.4a8.9,8.9,0,0,1,0,3.13L27.57,20Z" transform="translate(0 0)"/><path d="M16,22a6,6,0,1,1,6-6A5.94,5.94,0,0,1,16,22Zm0-10a3.91,3.91,0,0,0-4,4,3.91,3.91,0,0,0,4,4,3.91,3.91,0,0,0,4-4A3.91,3.91,0,0,0,16,12Z" transform="translate(0 0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32"/></svg>
                                            </span>
                                            and select <b>Kiveez</b>
                                        </p>
                                        <p>3. Point your phone to this screen to capture the code </p>
                                        <p><a href="#"><small>Need help to get started?</small></a></p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="scan__qr">
                                        <a href="#" onClick={this.checkUserData} >
                                            <QRCode id="qr_code_id" value={this.state.randomNumber} size={170} logo={logo}/>
                                        </a>
                                        <p>
                                            <span className={`keep__signin ${this.state.checked ? "active" : ""}`}
                                              onClick={this.handleKeepLoggedIn}>
                                                <span className="check__box">
                                                    <svg  xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                        <polygon points="14 21.414 9 16.413 10.413 15 14 18.586 21.585 11 23 12.415 14 21.414"/>
                                                        <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"/>
                                                        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32"/>
                                                    </svg>
                                                </span>
                                                <small>Keep me signed in</small>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scan__bottom">
                        <div className="row">
                            <div className="col-12">
                                <div className="scan__chat">
                                    <img onDragStart={(e) => { e.preventDefault() }} src={chat_ion} alt="chat"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
// export default ScanQr;
ScanQr.propTypes = {
    setCurrentUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default (connect(
    mapStateToProps,
    { setCurrentUser }
)(ScanQr));