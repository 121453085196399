/* Controller for loader*/
/* Initiated by steve (28/09/2020) */
/* Modified by steve (06/10/2020) */


import React, { Component, Fragment }  from "react";
import Favicon from "../assets/img/swa.ico";
import "../assets/scss/loader.scss";

class Loader extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <Fragment>
                <div className="loader__wrapper">
                    <div className="loader__inner">
                        <div id="particles-background" className="vertical-centered-box"></div>
                        <div id="particles-foreground" className="vertical-centered-box"></div>
                        <div className="vertical-centered-box">
                            <div className="content">
                                <div className="loader-circle"></div>
                                <div className="loader-line-mask">
                                    <div className="loader-line"></div>
                                </div>
                                <img onDragStart={(e) => { e.preventDefault() }} width="25px" src={Favicon} alt="loader"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Loader;