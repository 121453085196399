import { SET_CURRENT_ADMIN } from "../actions/types";

const isEmpty = require("is-empty");

const initialAdminState = {
	isAdminAuthenticated: false,
	admin: {},
};

export default function(state = initialAdminState, adminAction) {
	switch (adminAction.type) {
		case SET_CURRENT_ADMIN:
			return {
				...state,
				isAdminAuthenticated: !isEmpty(adminAction.payload),
				admin: adminAction.payload
			};
		default:
			return state;
	}
}
