/* Controller for chat list*/
/* Initiated by diyu (15/10/2020) */
/* Modified by diyu (15/10/2020) */

import React, { Component, Fragment }  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from 'moment';
import user__xs from '../assets/img/user__xs.jpg';
import users_img from '../assets/img/users_img.png';
import Spinner from 'react-bootstrap/Spinner';

class ArchiveChatList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            preLoader: false,
            activeUser: this.props.activeUserId,
        }
    }

    /*Function to open dropdown on chat list */
    openDropDown = (e) => {
        // e.stopPropagation();
        this.props.dropdownToggle(e);
    }

    /*Function to add default group icon when image is broken */
    addDefaultGroupIcon = (ev) => {
        ev.target.src = users_img;
    }

    /*Function to add user default icon when image is broken */
    addDefaultUserImg = (ev) => {
        ev.target.src = user__xs;
    }
    
    render() {
        var endDate = moment(new Date(), "MM/DD/YYYY");
        const {archiveChatListing, registeredUsers} = this.props;
        var name = "", chatUserData, message = '';
        return (
            <Fragment>
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="profile__setting">
                            <div className="chatLP__body">
                                <div className="chatL_users">
                                    {archiveChatListing !== undefined && archiveChatListing.length ? archiveChatListing.map((chat, i) => {
                                        //Last message time
                                        var messageTime = '';
                                        var d = new Date(parseInt(chat.timestamp, 10));
                                        var ds = d.toString('HH:mm:ss');
                                        var startDate = moment(ds).format("MM/DD/YYYY");
                                        var days = endDate.diff(startDate, 'days');
                                        if(days === 0) {
                                            messageTime = moment(ds).format("h:mm a");
                                        } else if (days === 1) {
                                            messageTime = 'Yesterday';
                                        } else if (days === 2 || days === 3 || days === 4 || days === 5 || days === 6 || days === 7) {
                                            messageTime = moment(ds).format("dddd");
                                        } else {
                                            messageTime = moment(ds).format("M/D/YYYY");
                                        }
                                        if (chat.chatType === 'single') {
                                            //Replace if device name is found for chat user name
                                            chatUserData = registeredUsers.find((obj => obj.phoneNumber === chat.phoneNumber));
                                            if(chatUserData !== undefined) {
                                                name = chatUserData.name;
                                            } else {
                                                name = chat.name;
                                            }
                                            return (<div key={i} data-key={i} className={this.props.activeUserId === chat.id ? "users__item active" : "users__item"} onClick={(event)=>{this.props.selectUserToChat(event,chat)}} >
                                                <div className="users__icon">
                                                    <img onDragStart={(e) => { e.preventDefault() }} onError={this.addDefaultUserImg} src={chat.image ? chat.image : user__xs} alt={name ? name : user__xs} />
                                                </div>
                                                <div className="user__detail">
                                                    <div className="userD_top">
                                                        <span className="userD__name">
                                                            <span data-id={chat.id} data-mutenotification={chat.isMute}>{name}</span>
                                                        </span>
                                                        <span className="userD__time">{messageTime}</span>
                                                    </div>
                                                    <div className="userD_bottom">
                                                        <span className="userD__msg archive_list_block">
                                                            {chat.messageType === 'text' && <span>{chat.message}</span>}
                                                            {chat.messageType === 'document' && <span>
                                                                <svg  className="chatType__icon" height="45" viewBox="0 0 512 512" width="45"><g><path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z"/><path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/></g></svg>
                                                                {chat.messageType}
                                                            </span>}
                                                            {chat.messageType === 'Image' && <span>
                                                                <svg className="chatType__icon" x="0px" y="0px" viewBox="0 0 420.8 420.8" > <path d="M406.8,96.4c-8.4-8.8-20-14-33.2-14h-66.4v-0.8c0-10-4-19.6-10.8-26c-6.8-6.8-16-10.8-26-10.8h-120 c-10.4,0-19.6,4-26.4,10.8c-6.8,6.8-10.8,16-10.8,26v0.8h-66c-13.2,0-24.8,5.2-33.2,14c-8.4,8.4-14,20.4-14,33.2v199.2 C0,342,5.2,353.6,14,362c8.4,8.4,20.4,14,33.2,14h326.4c13.2,0,24.8-5.2,33.2-14c8.4-8.4,14-20.4,14-33.2V129.6 C420.8,116.4,415.6,104.8,406.8,96.4z M400,328.8h-0.4c0,7.2-2.8,13.6-7.6,18.4s-11.2,7.6-18.4,7.6H47.2 c-7.2,0-13.6-2.8-18.4-7.6c-4.8-4.8-7.6-11.2-7.6-18.4V129.6c0-7.2,2.8-13.6,7.6-18.4s11.2-7.6,18.4-7.6h77.2 c6,0,10.8-4.8,10.8-10.8V81.2c0-4.4,1.6-8.4,4.4-11.2s6.8-4.4,11.2-4.4h119.6c4.4,0,8.4,1.6,11.2,4.4c2.8,2.8,4.4,6.8,4.4,11.2 v11.6c0,6,4.8,10.8,10.8,10.8H374c7.2,0,13.6,2.8,18.4,7.6s7.6,11.2,7.6,18.4V328.8z"/> <path d="M210.4,130.8c-27.2,0-52,11.2-69.6,28.8c-18,18-28.8,42.4-28.8,69.6s11.2,52,28.8,69.6c18,18,42.4,28.8,69.6,28.8 s52-11.2,69.6-28.8c18-18,28.8-42.4,28.8-69.6s-11.2-52-28.8-69.6C262.4,142,237.6,130.8,210.4,130.8z M264.8,284 c-14,13.6-33.2,22.4-54.4,22.4S170,297.6,156,284c-14-14-22.4-33.2-22.4-54.4c0-21.2,8.8-40.4,22.4-54.4 c14-14,33.2-22.4,54.4-22.4s40.4,8.8,54.4,22.4c14,14,22.4,33.2,22.4,54.4C287.6,250.8,278.8,270,264.8,284z"/> <circle cx="352.8" cy="150" r="19.6"/> </svg>
                                                                {chat.messageType}
                                                            </span>}
                                                            {chat.messageType === 'contact' && <span>
                                                                <svg className="chatType__icon" x="0px" y="0px" viewBox="0 0 512 512"> <path d="M256,0c-74.439,0-135,60.561-135,135s60.561,135,135,135s135-60.561,135-135S330.439,0,256,0z M256,240 c-57.897,0-105-47.103-105-105c0-57.897,47.103-105,105-105c57.897,0,105,47.103,105,105C361,192.897,313.897,240,256,240z"/> <path d="M297.833,301h-83.667C144.964,301,76.669,332.951,31,401.458V512h450V401.458C435.397,333.05,367.121,301,297.833,301z M451.001,482H451H61v-71.363C96.031,360.683,152.952,331,214.167,331h83.667c61.215,0,118.135,29.683,153.167,79.637V482z"/> </svg>
                                                                {chat.messageType}</span>
                                                            }
                                                            {chat.messageType === 'location' && <span>
                                                                <svg className="chatType__icon" x="0px" y="0px" viewBox="0 0 512 512" > <path d="M341.476,338.285c54.483-85.493,47.634-74.827,49.204-77.056C410.516,233.251,421,200.322,421,166 C421,74.98,347.139,0,256,0C165.158,0,91,74.832,91,166c0,34.3,10.704,68.091,31.19,96.446l48.332,75.84 C118.847,346.227,31,369.892,31,422c0,18.995,12.398,46.065,71.462,67.159C143.704,503.888,198.231,512,256,512 c108.025,0,225-30.472,225-90C481,369.883,393.256,346.243,341.476,338.285z M147.249,245.945 c-0.165-0.258-0.337-0.51-0.517-0.758C129.685,221.735,121,193.941,121,166c0-75.018,60.406-136,135-136 c74.439,0,135,61.009,135,136c0,27.986-8.521,54.837-24.646,77.671c-1.445,1.906,6.094-9.806-110.354,172.918L147.249,245.945z M256,482c-117.994,0-195-34.683-195-60c0-17.016,39.568-44.995,127.248-55.901l55.102,86.463 c2.754,4.322,7.524,6.938,12.649,6.938s9.896-2.617,12.649-6.938l55.101-86.463C411.431,377.005,451,404.984,451,422 C451,447.102,374.687,482,256,482z"/> <path d="M256,91c-41.355,0-75,33.645-75,75s33.645,75,75,75c41.355,0,75-33.645,75-75S297.355,91,256,91z M256,211 c-24.813,0-45-20.187-45-45s20.187-45,45-45s45,20.187,45,45S280.813,211,256,211z"/> </svg>
                                                                {chat.messageType}</span>
                                                            }
                                                            {chat.messageType === 'audio' && <span>
                                                                <svg className="chatType__icon" viewBox="0 0 32 32"><path d="M23,14v3A7,7,0,0,1,9,17V14H7v3a9,9,0,0,0,8,8.94V28H11v2H21V28H17V25.94A9,9,0,0,0,25,17V14Z"></path><path d="M16,22a5,5,0,0,0,5-5V7A5,5,0,0,0,11,7V17A5,5,0,0,0,16,22ZM13,7a3,3,0,0,1,6,0V17a3,3,0,0,1-6,0Z"></path></svg>
                                                                {chat.messageType}</span>
                                                            }
                                                            {(chat.messageType === "call" && chat.message.includes("voice")) && 
                                                                <span>
                                                                    <svg className="chatType__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 405.333 405.333">
                                                                        <g>
                                                                            <g>
                                                                                <path d="M373.333,266.88c-25.003,0-49.493-3.904-72.725-11.584c-11.328-3.904-24.171-0.896-31.637,6.699l-46.016,34.752
                                                                                    c-52.779-28.16-86.571-61.931-114.389-114.368l33.813-44.928c8.512-8.533,11.563-20.971,7.915-32.64
                                                                                    C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32
                                                                                    c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z
                                                                                    M384,373.333c0,5.888-4.8,10.667-10.667,10.667c-194.091,0-352-157.909-352-352c0-5.888,4.8-10.667,10.667-10.667h74.667
                                                                                    c5.867,0,10.667,4.779,10.667,10.667c0,27.243,4.267,53.995,12.629,79.36c1.237,3.989,0.235,8.107-3.669,12.16l-38.827,51.413
                                                                                    c-2.453,3.264-2.837,7.637-0.981,11.264c31.637,62.144,70.059,100.587,132.651,132.651c3.605,1.877,8.021,1.493,11.285-0.981
                                                                                    l52.523-39.808c2.859-2.816,7.061-3.797,10.859-2.539c25.515,8.427,52.267,12.693,79.531,12.693
                                                                                    c5.867,0,10.667,4.779,10.667,10.667V373.333z"/>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    {chat.messageType}
                                                                </span>
                                                            }
                                                            {(chat.messageType === "call" && chat.message.includes("video")) &&
                                                                <span>
                                                                    <svg className="chatType__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
                                                                        <g>
                                                                            <path d="M496,112h-48c-4.253-0.025-8.34,1.645-11.36,4.64L409.44,144H384v-16c0-26.51-21.49-48-48-48H48c-26.51,0-48,21.49-48,48
                                                                                v256c0,26.51,21.49,48,48,48h288c26.51,0,48-21.49,48-48v-16h25.44l27.36,27.36c2.98,2.956,7.003,4.622,11.2,4.64h48
                                                                                c8.837,0,16-7.163,16-16V128C512,119.163,504.837,112,496,112z M480,368h-25.44l-27.36-27.36c-2.98-2.956-7.003-4.622-11.2-4.64
                                                                                h-48c-8.837,0-16,7.163-16,16v32c0,8.837-7.163,16-16,16H48c-8.837,0-16-7.163-16-16V128c0-8.837,7.163-16,16-16h288
                                                                                c8.837,0,16,7.163,16,16v160h32V176h32c4.253,0.025,8.341-1.645,11.36-4.64l27.2-27.36H480V368z"/>
                                                                        </g>
                                                                    </svg>
                                                                    {chat.messageType}
                                                                </span>
                                                            }
                                                        </span>
                                                        <span className="userD__count">
                                                            <span>
                                                                {/* <span className="count__badge">{chat.messageCount}</span> */}
                                                                {/* <span className="unarchive__chat" onClick= {() => this.props.unArchiveUserChat(chat.id)} > Archived </span> */}
                                                                {chat.isMute === "1" &&
                                                                <span className="muted__user1"><svg className="rinfo__tool" width="19" height="19" viewBox="0 0 32 32"><polygon points="31 12.41 29.59 11 26 14.59 22.41 11 21 12.41 24.59 16 21 19.59 22.41 21 26 17.41 29.59 21 31 19.59 27.41 16 31 12.41"></polygon><path d="M18,30a1,1,0,0,1-.71-.3L9.67,22H3a1,1,0,0,1-1-1H2V11a1,1,0,0,1,1-1H9.67l7.62-7.7a1,1,0,0,1,1.41,0A1,1,0,0,1,19,3V29A1,1,0,0,1,18,30ZM4,20h6a1.17,1.17,0,0,1,.79.3L17,26.57V5.43L10.79,11.7A1.17,1.17,0,0,1,10,12H4Z"></path><rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width="32" height="32"></rect></svg></span> }
                                                            </span>
                                                            <span className="archive-sec">
                                                                <span className="dropleft dropdown" onClick={this.openDropDown}>
                                                                    <svg  data-toggle="dropdown" className="archive-arrow" width="612" height="612" viewBox="0 0 612 612">
                                                                    <path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782
                                                                        c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296
                                                                        c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"/>
                                                                    </svg>
                                                                    <div className="dropdown-right dropdown-menu">
                                                                        <a className="dropdown-item" href="#" onClick= {() => this.props.unArchiveUserChat(chat.id)}>Unarchived chat</a>
                                                                        {
                                                                            chat.isMute === "1" ? 
                                                                                <a className="dropdown-item mute__toggler" href="#" onClick={() => this.props.unMuteCurrentUser(chat.id)}>
                                                                                    <span className="mute__txt">Unmute Notification</span>
                                                                                </a>
                                                                            : 
                                                                                <a className="dropdown-item mute__toggler" href="#" onClick={() => this.props.muteCurrentUser(chat.id)}>
                                                                                    <span className="mute__txt">Mute Notification</span>
                                                                                </a> 
                                                                        }
                                                                        <a className="dropdown-item" href="#" onClick={() => this.props.confirmDeleteChat(chat.id)}>Delete chat</a>
                                                                        {(() => {
                                                                            if(chat.isBlockedByMe === "1") {
                                                                                return(
                                                                                    <a className="dropdown-item" href="#" onClick= {() => this.props.confirmUnBlockUser(this.props.userId, chat.id)}>Unblock</a>
                                                                                )
                                                                            } else if(chat.amIBlocked === "1") {
                                                                                return (
                                                                                    null
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <a className="dropdown-item" href="#" onClick= {() => this.props.confirmBlockUser(this.props.userId, chat.id)}>Block</a> 
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>)
                                        } else {
                                            message = chat.message;
                                            if(chat.messageType === "common" && chat.actionUsers !== undefined) {
                                                for (let i=0; i < chat.actionUsers.length; i++) {
                                                    let userInfo = chat.actionUsers[i];
                                                    var userName = registeredUsers.find((obj => obj.phoneNumber === userInfo.phoneNumber));
                                                    if(userInfo.userId === this.props.userId) {
                                                        message = message.replace(userInfo.name, "You");
                                                    } else if(userName !== undefined) {
                                                        message = message.replace(userInfo.name, userName.name);
                                                    }
                                                }
                                            }
                                            return (<div key={i}  data-key={i} className={this.props.activeUserId === chat.id ? "users__item group__chating active" : "users__item group__chating"} onClick={(event)=>{this.props.selectUserToChat(event,chat)}}>
                                                <div className="users__icon">
                                                    <img onDragStart={(e) => { e.preventDefault() }} onError={this.addDefaultGroupIcon} src={chat.image ? chat.image : users_img} alt={chat.name ? chat.name : users_img} alt="user__xs" />
                                                </div>
                                                <div className="user__detail">
                                                    <div className="userD_top">
                                                        <span className="userD__name">
                                                            <span data-id={chat.id} data-mutenotification={chat.isMute}>{chat.name}</span>
                                                        </span>
                                                        <span className="userD__time">{messageTime}</span>
                                                    </div>
                                                    <div className="userD_bottom">
                                                        <span className="userD__msg archive_list_block">
                                                            {chat.messageType === 'text' && <span>{message}</span>}
                                                            {chat.messageType === 'document' && <span>
                                                                <svg className="chatType__icon"  height="45" viewBox="0 0 512 512" width="45"><g><path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z"/><path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/></g></svg>
                                                                {chat.messageType}
                                                            </span>}
                                                            {chat.messageType === 'Image' && <span>
                                                                <svg className="chatType__icon" x="0px" y="0px" viewBox="0 0 420.8 420.8" > <path d="M406.8,96.4c-8.4-8.8-20-14-33.2-14h-66.4v-0.8c0-10-4-19.6-10.8-26c-6.8-6.8-16-10.8-26-10.8h-120 c-10.4,0-19.6,4-26.4,10.8c-6.8,6.8-10.8,16-10.8,26v0.8h-66c-13.2,0-24.8,5.2-33.2,14c-8.4,8.4-14,20.4-14,33.2v199.2 C0,342,5.2,353.6,14,362c8.4,8.4,20.4,14,33.2,14h326.4c13.2,0,24.8-5.2,33.2-14c8.4-8.4,14-20.4,14-33.2V129.6 C420.8,116.4,415.6,104.8,406.8,96.4z M400,328.8h-0.4c0,7.2-2.8,13.6-7.6,18.4s-11.2,7.6-18.4,7.6H47.2 c-7.2,0-13.6-2.8-18.4-7.6c-4.8-4.8-7.6-11.2-7.6-18.4V129.6c0-7.2,2.8-13.6,7.6-18.4s11.2-7.6,18.4-7.6h77.2 c6,0,10.8-4.8,10.8-10.8V81.2c0-4.4,1.6-8.4,4.4-11.2s6.8-4.4,11.2-4.4h119.6c4.4,0,8.4,1.6,11.2,4.4c2.8,2.8,4.4,6.8,4.4,11.2 v11.6c0,6,4.8,10.8,10.8,10.8H374c7.2,0,13.6,2.8,18.4,7.6s7.6,11.2,7.6,18.4V328.8z"/> <path d="M210.4,130.8c-27.2,0-52,11.2-69.6,28.8c-18,18-28.8,42.4-28.8,69.6s11.2,52,28.8,69.6c18,18,42.4,28.8,69.6,28.8 s52-11.2,69.6-28.8c18-18,28.8-42.4,28.8-69.6s-11.2-52-28.8-69.6C262.4,142,237.6,130.8,210.4,130.8z M264.8,284 c-14,13.6-33.2,22.4-54.4,22.4S170,297.6,156,284c-14-14-22.4-33.2-22.4-54.4c0-21.2,8.8-40.4,22.4-54.4 c14-14,33.2-22.4,54.4-22.4s40.4,8.8,54.4,22.4c14,14,22.4,33.2,22.4,54.4C287.6,250.8,278.8,270,264.8,284z"/> <circle cx="352.8" cy="150" r="19.6"/> </svg>
                                                                {chat.messageType}
                                                            </span>}
                                                            {chat.messageType === 'contact' && <span>
                                                                <svg className="chatType__icon" x="0px" y="0px" viewBox="0 0 512 512"> <path d="M256,0c-74.439,0-135,60.561-135,135s60.561,135,135,135s135-60.561,135-135S330.439,0,256,0z M256,240 c-57.897,0-105-47.103-105-105c0-57.897,47.103-105,105-105c57.897,0,105,47.103,105,105C361,192.897,313.897,240,256,240z"/> <path d="M297.833,301h-83.667C144.964,301,76.669,332.951,31,401.458V512h450V401.458C435.397,333.05,367.121,301,297.833,301z M451.001,482H451H61v-71.363C96.031,360.683,152.952,331,214.167,331h83.667c61.215,0,118.135,29.683,153.167,79.637V482z"/> </svg>
                                                                {chat.messageType}</span>
                                                            }
                                                            {chat.messageType === 'location' && <span>
                                                                <svg className="chatType__icon" x="0px" y="0px" viewBox="0 0 512 512" > <path d="M341.476,338.285c54.483-85.493,47.634-74.827,49.204-77.056C410.516,233.251,421,200.322,421,166 C421,74.98,347.139,0,256,0C165.158,0,91,74.832,91,166c0,34.3,10.704,68.091,31.19,96.446l48.332,75.84 C118.847,346.227,31,369.892,31,422c0,18.995,12.398,46.065,71.462,67.159C143.704,503.888,198.231,512,256,512 c108.025,0,225-30.472,225-90C481,369.883,393.256,346.243,341.476,338.285z M147.249,245.945 c-0.165-0.258-0.337-0.51-0.517-0.758C129.685,221.735,121,193.941,121,166c0-75.018,60.406-136,135-136 c74.439,0,135,61.009,135,136c0,27.986-8.521,54.837-24.646,77.671c-1.445,1.906,6.094-9.806-110.354,172.918L147.249,245.945z M256,482c-117.994,0-195-34.683-195-60c0-17.016,39.568-44.995,127.248-55.901l55.102,86.463 c2.754,4.322,7.524,6.938,12.649,6.938s9.896-2.617,12.649-6.938l55.101-86.463C411.431,377.005,451,404.984,451,422 C451,447.102,374.687,482,256,482z"/> <path d="M256,91c-41.355,0-75,33.645-75,75s33.645,75,75,75c41.355,0,75-33.645,75-75S297.355,91,256,91z M256,211 c-24.813,0-45-20.187-45-45s20.187-45,45-45s45,20.187,45,45S280.813,211,256,211z"/> </svg>
                                                                {chat.messageType}</span>
                                                            }
                                                            {chat.messageType === 'audio' && <span>
                                                                <svg className="chatType__icon" viewBox="0 0 32 32"><path d="M23,14v3A7,7,0,0,1,9,17V14H7v3a9,9,0,0,0,8,8.94V28H11v2H21V28H17V25.94A9,9,0,0,0,25,17V14Z"></path><path d="M16,22a5,5,0,0,0,5-5V7A5,5,0,0,0,11,7V17A5,5,0,0,0,16,22ZM13,7a3,3,0,0,1,6,0V17a3,3,0,0,1-6,0Z"></path></svg>
                                                                {chat.messageType}</span>
                                                            }
                                                            {(chat.messageType === "call" && message.includes("voice")) && 
                                                                <span>
                                                                    <svg className="chatType__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 405.333 405.333">
                                                                        <g>
                                                                            <g>
                                                                                <path d="M373.333,266.88c-25.003,0-49.493-3.904-72.725-11.584c-11.328-3.904-24.171-0.896-31.637,6.699l-46.016,34.752
                                                                                    c-52.779-28.16-86.571-61.931-114.389-114.368l33.813-44.928c8.512-8.533,11.563-20.971,7.915-32.64
                                                                                    C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32
                                                                                    c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z
                                                                                    M384,373.333c0,5.888-4.8,10.667-10.667,10.667c-194.091,0-352-157.909-352-352c0-5.888,4.8-10.667,10.667-10.667h74.667
                                                                                    c5.867,0,10.667,4.779,10.667,10.667c0,27.243,4.267,53.995,12.629,79.36c1.237,3.989,0.235,8.107-3.669,12.16l-38.827,51.413
                                                                                    c-2.453,3.264-2.837,7.637-0.981,11.264c31.637,62.144,70.059,100.587,132.651,132.651c3.605,1.877,8.021,1.493,11.285-0.981
                                                                                    l52.523-39.808c2.859-2.816,7.061-3.797,10.859-2.539c25.515,8.427,52.267,12.693,79.531,12.693
                                                                                    c5.867,0,10.667,4.779,10.667,10.667V373.333z"/>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    {chat.messageType}
                                                                </span>
                                                            }
                                                            {(chat.messageType === "call" && message.includes("video")) &&
                                                                <span>
                                                                    <svg className="chatType__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
                                                                        <g>
                                                                            <path d="M496,112h-48c-4.253-0.025-8.34,1.645-11.36,4.64L409.44,144H384v-16c0-26.51-21.49-48-48-48H48c-26.51,0-48,21.49-48,48
                                                                                v256c0,26.51,21.49,48,48,48h288c26.51,0,48-21.49,48-48v-16h25.44l27.36,27.36c2.98,2.956,7.003,4.622,11.2,4.64h48
                                                                                c8.837,0,16-7.163,16-16V128C512,119.163,504.837,112,496,112z M480,368h-25.44l-27.36-27.36c-2.98-2.956-7.003-4.622-11.2-4.64
                                                                                h-48c-8.837,0-16,7.163-16,16v32c0,8.837-7.163,16-16,16H48c-8.837,0-16-7.163-16-16V128c0-8.837,7.163-16,16-16h288
                                                                                c8.837,0,16,7.163,16,16v160h32V176h32c4.253,0.025,8.341-1.645,11.36-4.64l27.2-27.36H480V368z"/>
                                                                        </g>
                                                                    </svg>
                                                                    {chat.messageType}
                                                                </span>
                                                            }
                                                            {(chat.messageType === "common") && 
                                                                <span>{message}</span>
                                                            }
                                                        </span>
                                                        <span className="userD__count">
                                                            <span>
                                                                {/* <span className="count__badge">{chat.messageCount}</span> */}
                                                                {/* <span className="unarchive__chat" data-id={chat.id} onClick={() => this.props.unArchiveGroupChat(chat.id)}>Archived</span> */}
                                                                {chat.isMute === "1" &&
                                                                    <span className="muted__user1"><svg className="rinfo__tool" width="19" height="19" viewBox="0 0 32 32"><polygon points="31 12.41 29.59 11 26 14.59 22.41 11 21 12.41 24.59 16 21 19.59 22.41 21 26 17.41 29.59 21 31 19.59 27.41 16 31 12.41"></polygon><path d="M18,30a1,1,0,0,1-.71-.3L9.67,22H3a1,1,0,0,1-1-1H2V11a1,1,0,0,1,1-1H9.67l7.62-7.7a1,1,0,0,1,1.41,0A1,1,0,0,1,19,3V29A1,1,0,0,1,18,30ZM4,20h6a1.17,1.17,0,0,1,.79.3L17,26.57V5.43L10.79,11.7A1.17,1.17,0,0,1,10,12H4Z"></path><rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width="32" height="32"></rect></svg></span> 
                                                                }
                                                            </span>
                                                            <span className="archive-sec">
                                                                <span className="dropleft dropdown" onClick={this.openDropDown}>
                                                                    <svg data-toggle="dropdown" className="archive-arrow" width="612" height="612" viewBox="0 0 612 612">
                                                                    <path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782
                                                                        c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296
                                                                        c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"/>
                                                                    </svg>
                                                                
                                                                    <div className="dropdown-right dropdown-menu">
                                                                        <a className="dropdown-item" href="#" onClick={() => this.props.unArchiveGroupChat(chat.id)}>Unarchived chat</a>
                                                                        {
                                                                            chat.isMute === "1" ? 
                                                                                <a className="dropdown-item mute__toggler" href="#" onClick={() => this.props.unMuteGroupChat(chat.id)}>
                                                                                    <span className="mute__txt">Unmute Notification</span>
                                                                                </a>
                                                                            : 
                                                                                <a className="dropdown-item mute__toggler" href="#" onClick={() => this.props.muteGroupChat(chat.id)}>
                                                                                    <span className="mute__txt">Mute Notification</span>
                                                                                </a> 
                                                                        }
                                                                        {
                                                                            (chat.isExit === "1") ?
                                                                                <a className="dropdown-item" href="#" onClick={() => this.props.confirmDeleteGroup(chat.id)}>Delete group</a>
                                                                            :
                                                                                <a className="dropdown-item" href="#" onClick={() => this.props.confirmExitGroup(chat.id)}>Exit group</a>
                                                                        }
                                                                    </div>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    }) : 
                                        <div className="archived-wallpaper">
                                            <div className="archived-wallpaper-inner">
                                                <span data-testid="empty-archived" data-icon="empty-archived" className="archive-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90">
                                                        <path  d="M71.109 24.113l-4.288-5.222c-.594-.934-1.825-1.528-3.396-1.528h-36.85a5.083 5.083 0 0 0-3.693 1.528l-3.991 5.222c-.934 1.231-1.528 2.462-1.528 3.991v38.377a6.16 6.16 0 0 0 6.156 6.156h42.962a6.16 6.16 0 0 0 6.156-6.156V28.104c0-1.529-.595-2.76-1.528-3.991zM45.042 61.896L28.146 45h10.741v-6.156h12.269V45h10.741L45.042 61.896zM23.859 23.519l2.462-3.057H63.17l2.759 3.057h-42.07z">
                                                        </path>
                                                    </svg>
                                                </span>
                                                <p className="no-search-message">
                                                    <span>No archived chats</span>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

ArchiveChatList.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default (connect(
    mapStateToProps
)(ArchiveChatList));