import React, { useEffect, useState } from 'react'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Bar, Doughnut, Line, Pie} from 'react-chartjs-2';
import { Card} from "react-bootstrap";

export default function Platform() {
    const [reports, setReports] = useState([]);
    const [chartdata, setChartData] = useState([]);
    const [labels, setLabels] = useState([]);

    const columns = [{
       dataField: 'id',
       text: 'Id'
     }, {
       dataField: 'dimension',
       text: 'DimensionName',
     }, {
       dataField: 'activeUsers',
       text: 'ActiveUsers'
   }];
  const  loadRealtimeReportsFromServer = (dimensionName, metricsName) => {
       // this.setState({ loading: true })
       let reportData = {
           dimensionName: dimensionName,
           metricsName: metricsName
       }
       axios.post(`${process.env.REACT_APP_API_URL}/api/runtimereports/generateReal`, reportData)
       .then(result => {
           let reportsData = [];
           let datachart= [];
           let labelsdata= [];
           let response = result.data;
        //    console.log('real time report')
        //    console.log(response.reports)
        //    console.log(response.reports[0].rows[1].dimensionValues[0].value)
           if (response.success) {
               if(response.reports) {
                   let reports = response.reports ? response.reports[0].rows : "";
                   // console.log(reports)
                   if(reports.length) {
                       reports.forEach((report, i) => {
                    //        report = {
                    //            id: i+1,
                    //            dimension: report.dimensionValues[0].value,
                    //            activeUsers: report.metricValues[0].value,
                    //        }  ;       
                    //     reportsData.push(report)
                        //    labelsdata.push(report.dimensionValues[0].value) ;
                           datachart.push(report.metricValues[0].value) 

                       });

                   }
                   setReports(reportsData); // report state.
                   setChartData(datachart); // set chart state
                //    setLabels(labelsdata); // set chart label state
                //    setChartData(datachart); // set chart data state
               }
           }
       })
       .catch(error => {
           // this.setState({
           // 	preLoader: false,
           // 	loader: false
           // });
           console.log(error);
       });
   };

   useEffect(() => {
    //    let startDate = "2021-04-11",
    //        endDate = "today",
          let dimensionName = "audienceName",
              metricsName = "activeUsers";
                loadRealtimeReportsFromServer(dimensionName, metricsName);;
            // setInterval(async () => {
            //     loadRealtimeReportsFromServer(dimensionName, metricsName)
            // }, 15000);
   }, []);

   return (
       <>

           {/* <BootstrapTable 
                   keyField='id' 
                   data={ reports } 
                   columns={ columns } 
                   pagination={ paginationFactory() } 
            /> */}
            
                <Card >
                    <Card.Body>
                        <Card.Title tag="h5">{chartdata}</Card.Title>
                        Users per minute
                    </Card.Body>
                </Card>
           {/* <Bar data = {data} width={50} height={20}></Bar> */}
       </>
   )
}
